import http from "../http-common";

class TagsService {
  getAll() {
    return http.get("/tagGroup");
  }

  get(id) {
    return http.get(`/tagGroup/${id}`);
  }

  create(data) {
    return http.post("/tagGroup", data);
  }

  update(data) {
    return http.put(`/tagGroup`, data);
  }

  delete(id) {
    return http.delete(`/tagGroup/${id}`);
  }
}

export default new TagsService();
