import http from "../http-common";

class DrawingNoteService {
  get(id) {
    return http.get(`/drawingNotes/${id}`);
  }

  update(data) {
    return http.put(`/drawingNotes`, data);
  }

  updateDestination(data) {
    return http.put("drawingNotes/destination", data);
  }

  delete(id) {
    return http.delete(`/drawingNotes/${id}`);
  }
}

export default new DrawingNoteService();
