import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";

let visitorId;

class ReportVisitService {
  report(path, title) {
    if (!visitorId) {
      visitorId = uuidv4();
    }

    const data = {
      id: "4c6f5fd3-1bfa-4212-aa4e-372eb5548158",
      path: path,
      pageName: title,
      visitorId: visitorId,
    };
    http.post("/", data);
  }
}

export default new ReportVisitService();
