import React, { Component } from "react";

export default class SelectNewDestinationFolder extends Component {
  constructor(props) {
    super(props);

    this.onSelectedFolder = this.onSelectedFolder.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      folders: this.props.folders,
    };
  }

  onSelectedFolder(folderId, folderName) {
    this.props.onClose(true, folderId, folderName);
  }

  onClose() {
    this.props.onClose(false, undefined);
  }

  render() {
    const folders = this.state.folders;

    return (
      <div className="dialog_wrapper">
        <div className="dialog select_dialog">
          <div className="dialog_header">
            <button
              title="Close"
              className="action_button action_close"
              onClick={this.onClose}
            />
            <label className="label_title">Select new destination</label>
          </div>
          <div className="select_dialog_body">
            {folders && folders.length > 0 ? (
              <div>
                {folders.map((folder) => (
                  <div
                    className="folder_row"
                    key={folder.id}
                    onClick={() =>
                      this.onSelectedFolder(folder.id, folder.name)
                    }
                  >
                    <label className="folder_row_label">{folder.name}</label>
                  </div>
                ))}
              </div>
            ) : (
              <label className="no_folders">No folders</label>
            )}
          </div>
        </div>
      </div>
    );
  }
}
