import React, { Component } from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DeleteDialog from "../dialog/delete-dialog.component";
import { Link, Navigate } from "react-router-dom";
import FolderService from "../../services/folder.service";
import methods from "../../methods";
import SelectNewDestinationFolder from "../dialog/select-new-destination-folder.component";

export default class FolderRow extends Component {
  constructor(props) {
    super(props);

    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onCloseSelectDialog = this.onCloseSelectDialog.bind(this);

    this.state = {
      folder: this.props.folder,
      showDeleteDialog: false,
      selectFolders: [],
      showSelectFolderDialog: false,
    };
  }

  onCloseSelectDialog(selected, folderId, folderName) {
    if (selected) {
      const newDestination = {
        id: this.state.folder.id,
        destinationId: folderId,
      };

      FolderService.updateDestination(newDestination)
        .then(() => {
          this.props.refresh();
          this.props.showNotification(
            "info",
            "Folder " + this.state.folder.title + " was moved to " + folderName
          );
        })
        .catch((e) => {
          methods.processError(e);
        });
    }

    this.props.onContextMenuVisibilityChange(false);

    this.setState({
      showSelectFolderDialog: false,
    });
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      FolderService.delete(this.state.id)
        .then(() => {
          this.props.refresh();
          this.props.showNotification(
            "success",
            "Folder " + this.state.folder.title + " was deleted"
          );
        })
        .catch((e) => {
          methods.processError(e);
        });
    }

    this.setState({
      showDeleteDialog: false,
    });
  }

  render() {
    const editFolder = () => {
      this.setState({
        showEditDialog: true,
      });
    };

    const deleteItem = () => {
      this.setState({
        showDeleteDialog: true,
      });
    };

    const moveFolder = () => {
      const folders = this.props.getFoldersForMove(this.state.folder.id);
      this.setState(
        {
          selectFolders: folders,
          showSelectFolderDialog: true,
        },
        () => this.props.onContextMenuVisibilityChange(true)
      );
    };

    const id = this.state.folder.id;
    const title = this.state.folder.title;
    const color = this.state.folder.color;
    const showDeleteDialog = this.state.showDeleteDialog;
    const showEditDialog = this.state.showEditDialog;

    return (
      <div>
        <ContextMenuTrigger id={"ctxMenu" + id}>
          <Link
            className={color !== "default" ? "row background_" + color : "row"}
            to={"/" + id}
          >
            <div className="item_content">
              {title && title.length > 0 ? (
                <label className="row_title">{title}</label>
              ) : null}
            </div>
          </Link>
        </ContextMenuTrigger>

        <ContextMenu
          onShow={() => this.props.onContextMenuVisibilityChange(true)}
          onHide={() => this.props.onContextMenuVisibilityChange(false)}
          id={"ctxMenu" + id}
        >
          {/* <MenuItem onClick={editFolder}>
            <span>Edit folder</span>
          </MenuItem> */}
          <MenuItem onClick={moveFolder}>
            <span>Move folder</span>
          </MenuItem>
          <MenuItem onClick={deleteItem}>
            <span>Delete</span>
          </MenuItem>
        </ContextMenu>

        {showDeleteDialog ? (
          <DeleteDialog
            type="folder"
            itemId={id}
            onDeleteConfirm={this.onDeleteConfirm}
          />
        ) : null}

        {showEditDialog ? (
          <Navigate
            to={
              (this.state.folder.folderId !== null
                ? "/" + this.state.folder.folderId + "/"
                : "/") +
              "folder/" +
              this.state.folder.id
            }
            replace={true}
          />
        ) : null}

        {this.state.showSelectFolderDialog ? (
          <SelectNewDestinationFolder
            folders={this.state.selectFolders}
            onClose={this.onCloseSelectDialog}
          />
        ) : null}
      </div>
    );
  }
}
