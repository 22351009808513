import React, { Component } from "react";
import ItemsService from "../services/items.service";
import { Link, Navigate } from "react-router-dom";
import CookieHelper from "../cookie-helper";
import FolderRow from "./row/folder-row.component";
import NoteRow from "./row/note-row.component";
import Masonry from "react-masonry-css";
import ListTagGroupDialogComponent from "./tag/list-tag-group-dialog.component";
import methods from "../methods";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import reportVisitService from "../reporing-visit/report-visit.service";

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.getData = this.getData.bind(this);
    this.autoHeight = this.autoHeight.bind(this);
    this.singOut = this.singOut.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setThemeColor = this.setThemeColor.bind(this);
    this.setSearchVisibility = this.setSearchVisibility.bind(this);
    this.searching = this.searching.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onLoadingStateChange = this.onLoadingStateChange.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.onContextMenuVisibilityChange =
      this.onContextMenuVisibilityChange.bind(this);
    this.showTagGroupsDialog = this.showTagGroupsDialog.bind(this);
    this.onCloseTagGroupsDialog = this.onCloseTagGroupsDialog.bind(this);
    this.showTags = this.showTags.bind(this);
    this.selectTag = this.selectTag.bind(this);
    this.setItems = this.setItems.bind(this);
    this.getFoldersForMove = this.getFoldersForMove.bind(this);
    this.showNotification = this.showNotification.bind(this);

    this.state = {
      id: null,
      parentId: null,
      title: null,
      pathname: null,
      folders: [],
      notes: [],
      pinnedNotes: [],
      backupFolders: [],
      backupNotes: [],
      backupPinnedNotes: [],
      tags: [],
      tagGroup: null,
      loadingItems: true,
      color: "default",
      accountInfo_class: "action_button action_account_info_white",
      refresh_class: "action_button action_refresh_white",
      dropdown_content_class: "dropdown_content dropdown_content_default",
      search_class: "action_button action_search_white",
      new_class: "action_button action_new_white",
      editIcon: require("../icons/edit.svg").default,
      menu_divider_class: "menu_divider",
      title_class: "title title_white",
      isSearchVisible: false,
      syncIntervalId: "",
      pathnameCheckerIntervalId: "",
      canSync: true,
      showTagGroupsDialog: false,
      showTags: false,
      selectedTagId: null,
      searchWords: "",
      randomNumber: 0,
    };
  }

  selectTag(tagId) {
    this.setState(
      {
        selectedTagId: tagId,
      },
      () => this.setItems()
    );
  }

  setSearchVisibility = () => {
    if (this.state.isSearchVisible) {
      this.setState(
        {
          isSearchVisible: false,
          canSync: true,
          searchWords: "",
        },
        this.setItems
      );
    } else {
      this.setState(
        {
          isSearchVisible: true,
          canSync: false,
        },
        () => {
          document.getElementById("input_search").focus();
        }
      );
    }
  };

  showNotification(type, message) {
    switch (type) {
      case "info":
        NotificationManager.info(message);
        break;
      case "error":
        NotificationManager.error(message);
        break;
      case "success":
        NotificationManager.success(message);
        break;
      case "warning":
        NotificationManager.warning(message);
        break;
      default:
        break;
    }
  }

  getFoldersForMove(exceptFolderId) {
    let folders = [];
    let currentFolders = this.state.backupFolders;
    if (this.state.id !== undefined && this.state.id !== null) {
      folders.push({
        name: "Parent folder",
        id: this.state.parentId,
      });
    }

    if (exceptFolderId !== undefined) {
      currentFolders = currentFolders.filter(
        (folder) => folder.id !== exceptFolderId
      );
    }

    currentFolders.forEach((folder) => {
      folders.push({
        name: folder.title,
        id: folder.id,
      });
    });
    return folders;
  }

  setItems() {
    const searchWords = this.state.searchWords;
    const selectedTagId = this.state.selectedTagId;
    const folders = this.state.backupFolders;
    const notes = this.state.backupNotes;
    const pinnedNotes = this.state.backupPinnedNotes;

    this.state.pinnedNotes = [];
    this.state.folders = [];
    this.state.notes = [];

    const tempNotes = [];
    const tempPinnedNotes = [];

    const finalFolders = [];
    const finalNotes = [];
    const finalPinnedNotes = [];

    if (searchWords.length > 0) {
      folders.forEach((item) => {
        if (
          (item.title !== undefined &&
            item.title !== null &&
            item.title.toLowerCase().includes(searchWords)) ||
          (item.content !== undefined &&
            item.content !== null &&
            item.content.toLowerCase().includes(searchWords))
        ) {
          finalFolders.push(item);
        }
      });

      notes.forEach((item) => {
        if (
          (item.title !== undefined &&
            item.title !== null &&
            item.title.toLowerCase().includes(searchWords)) ||
          (item.content !== undefined &&
            item.content !== null &&
            item.content.toLowerCase().includes(searchWords))
        ) {
          tempNotes.push(item);
        }
      });

      pinnedNotes.forEach((item) => {
        if (
          (item.title !== undefined &&
            item.title !== null &&
            item.title.toLowerCase().includes(searchWords)) ||
          (item.content !== undefined &&
            item.content !== null &&
            item.content.toLowerCase().includes(searchWords))
        ) {
          tempPinnedNotes.push(item);
        }
      });
    } else {
      finalFolders.push(...folders);
      tempNotes.push(...notes);
      tempPinnedNotes.push(...pinnedNotes);
    }

    if (selectedTagId === null) {
      finalPinnedNotes.push(...tempPinnedNotes);
      finalNotes.push(...tempNotes);
    } else {
      tempNotes.forEach((note) => {
        if (note.tag !== undefined && note.tag !== null) {
          if (note.tag.id === selectedTagId) {
            finalNotes.push(note);
          }
        }
      });

      tempPinnedNotes.forEach((note) => {
        if (note.tag !== undefined && note.tag !== null) {
          if (note.tag.id === selectedTagId) {
            finalPinnedNotes.push(note);
          }
        }
      });
    }

    this.setState({
      folders: finalFolders,
      notes: finalNotes,
      pinnedNotes: finalPinnedNotes,
    });
  }

  searching(e) {
    const searchWords = e.target.value.toLowerCase();
    this.setState(
      {
        searchWords: searchWords,
      },
      () => this.setItems()
    );
  }

  singOut = () => {
    if (window.confirm("Do you really want log out?")) {
      CookieHelper.remove();
      window.location.href =
        "https://autentication.davidsimak.dev/?redirect=notes";
    }
  };

  refresh = () => {
    clearInterval(this.state.syncIntervalId);
    this.getData();
    this.initIntervals();
  };

  componentDidMount() {
    this.setState({
      showTags: false,
    });
    this.getData();
    this.initIntervals();
    document.addEventListener("keydown", this.handleEscapeKey, false);
    window.addEventListener("resize", this.autoHeight);
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
    clearInterval(this.state.pathnameCheckerIntervalId);
    document.removeEventListener("keydown", this.handleEscapeKey, false);
    window.removeEventListener("resize", this.autoHeight);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.pathname !== window.location.pathname) {
      this.setState({
        showTags: false,
      });
      this.getData();
      reportVisitService.report(window.location.pathname, document.title);
    }
    this.autoHeight();
  }

  handleEscapeKey(e) {
    if (e.key.toLowerCase() === "e" && e.ctrlKey) {
      if (this.state.id !== null) {
        window.location.pathname =
          "/" + this.state.id + "/folder/" + this.state.id;
      }
    } else if (e.key.toLowerCase() === "f" && e.ctrlKey) {
      e.preventDefault();
      this.setSearchVisibility();
    } else if (e.key.toLowerCase() === "r" && e.ctrlKey) {
      e.preventDefault();
      this.refresh();
    } else if (e.key === "Escape") {
      const parentId = this.state.parentId;
      <Navigate to={parentId !== null ? "/" + parentId : "/"} replace={true} />;
    }
  }

  getData() {
    const pathname = window.location.pathname;

    this.setState({
      loadingItems: true,
      pathname: pathname,
    });

    const pathNameSplit = pathname.split("/");

    if (
      pathname === "/" ||
      pathNameSplit[1].includes("Note") ||
      pathNameSplit[1].includes("older")
    ) {
      this.getItems(null);
    } else {
      let id = pathNameSplit[1];
      this.getItems(id);
    }
  }

  getItems(id) {
    if (id !== null) {
      ItemsService.getByFolder(id)
        .then((response) => {
          this.setState({
            folders: [],
            notes: [],
            pinnedNotes: [],
            backupFolders: [],
            backupNotes: [],
            backupPinnedNotes: [],
            tags: [],
          });

          const items = response.data;
          const folderInfo = items.folderInfo;

          let tempTags = [];

          tempTags.push({
            id: null,
            title: "All notes",
          });

          tempTags.push(...items.tags);

          this.setState(
            {
              title: folderInfo.title,
              parentId: folderInfo.parentId,
              tagGroup: folderInfo.tagGroup,
              id: folderInfo.id,
              color: folderInfo.color,
              backupFolders: items.folders,
              backupNotes: items.notes,
              backupPinnedNotes: items.pinnedNotes,
              tags: tempTags,
              loadingItems: false,
            },
            () => this.setItems()
          );

          document.title = folderInfo.title + " | Notes";

          this.setThemeColor(folderInfo.color);
          CookieHelper.extendValidity();
        })
        .catch((e) => {
          methods.processError(e);
        });
    } else {
      ItemsService.getAll()
        .then((response) => {
          this.setState({
            folders: [],
            notes: [],
            pinnedNotes: [],
            backupFolders: [],
            backupNotes: [],
            backupPinnedNotes: [],
            tags: [],
          });

          this.setState({
            title: "",
            parentId: null,
            id: null,
            color: "default",
          });

          this.setThemeColor("default");

          const items = response.data;

          this.setState(
            {
              backupFolders: items.folders,
              backupNotes: items.notes,
              backupPinnedNotes: items.pinnedNotes,
              loadingItems: false,
            },
            () => this.setItems()
          );

          document.title = "Notes";
          CookieHelper.extendValidity();
        })
        .catch((e) => {
          methods.processError(e);
        });
    }
  }

  setThemeColor(colorToChange) {
    let tempDropdownContentClass =
      "dropdown_content dropdown_content_" + colorToChange;

    this.setState({
      dropdown_content_class: tempDropdownContentClass,
      header_class: "header background_" + colorToChange,
    });
  }

  autoHeight() {
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:1px;overflow-y:hidden;");
      tx[i].setAttribute(
        "style",
        "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
      );
    }
  }

  onLoadingStateChange(loading) {
    this.setState({
      loadingItems: loading,
    });
  }

  backgroundSync() {
    if (this.state.canSync) {
      const id = this.state.id;
      this.getItems(id);
    }
  }

  initIntervals() {
    const syncIntervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);

    const pathnameCheckerIntervalId = setInterval(() => {
      if (window.location.pathname !== this.state.pathname) {
        this.getData();
        this.setState({
          showTags: false,
        });
      }
    }, 100);

    this.setState({
      syncIntervalId: syncIntervalId,
      pathnameCheckerIntervalId: pathnameCheckerIntervalId,
    });
  }

  onContextMenuVisibilityChange = (contextMenuVisible) => {
    this.setState({
      canSync: !contextMenuVisible,
    });
  };

  onCloseTagGroupsDialog() {
    this.setState({
      showTagGroupsDialog: false,
    });
    const id = this.state.id;
    this.getItems(id);
  }

  showTagGroupsDialog() {
    this.setState({
      showTagGroupsDialog: true,
    });
  }

  showTags() {
    this.setState({
      showTags: !this.state.showTags,
    });
  }

  render() {
    // Data
    const id = this.state.id;
    const parentId = this.state.parentId;
    const title = this.state.title;
    const folders = this.state.folders;
    const notes = this.state.notes;
    const pinnedNotes = this.state.pinnedNotes;
    const tagGroup = this.state.tagGroup;
    const tags = this.state.tags;
    const selectedTagId = this.state.selectedTagId;

    // CSS classes
    const dropdown_content_class = this.state.dropdown_content_class;

    // UI changes
    const loadingItems = this.state.loadingItems;
    const isSearchVisible = this.state.isSearchVisible;
    const hasItems =
      (folders && folders.length > 0) ||
      (notes && notes.length > 0) ||
      (pinnedNotes && pinnedNotes.length > 0);
    const showTags = this.state.showTags;
    const breakpointCols = {
      default: 4,
      1700: 3,
      1200: 2,
      800: 1,
    };

    return (
      <div>
        {loadingItems ? <div id="loader" /> : null}

        <NotificationContainer />

        <div>
          <header className={this.state.header_class}>
            {id === null ? (
              <div className="title">
                <img
                  src={require("../icons/new_notes_icon.svg").default}
                  alt="Notes"
                  height="40"
                  width="40"
                  style={{ marginLeft: "10px" }}
                />
                <h1>Notes</h1>
              </div>
            ) : (
              <div className="title">
                <Link to={parentId !== null ? "/" + parentId : "/"}>
                  <img
                    title="Back"
                    src={require("../icons/left.svg").default}
                    height="40"
                    width="40"
                    alt="Back"
                    style={{ marginLeft: "10px" }}
                  />
                </Link>
                <h1>{title}</h1>
              </div>
            )}

            <nav>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.davidsimak.dev/notes"
                  >
                    <img
                      title="About App"
                      src={require("../icons/about.svg").default}
                      alt="About app"
                      height="30"
                      width="30"
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    />
                  </a>
                </li>
                <li>
                  <div className="dropdown">
                    <button
                      title="Account Info"
                      className="action_button action_account_info"
                    />
                    <div
                      className={
                        dropdown_content_class +
                        " dropdown_content_account_info"
                      }
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.davidsimak.dev/account/"
                      >
                        Account Info
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={this.singOut}>Sign out</a>
                    </div>
                  </div>
                </li>
                <li className="menu_divider">|</li>
                <li>
                  <button
                    title="Refresh"
                    className="action_button action_refresh"
                    onClick={this.refresh}
                  />
                </li>
                {id !== null ? (
                  <li>
                    <Link to={"/" + id + "/folder/" + id}>
                      <img
                        title="Edit"
                        src={require("../icons/edit.svg").default}
                        height="30"
                        width="30"
                        alt="Edit"
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </Link>
                  </li>
                ) : null}
                <li>
                  <button
                    title="Search"
                    className="action_button action_search"
                    onClick={this.setSearchVisibility}
                  />
                </li>

                {id === null ? (
                  <li>
                    <button
                      title="Tags"
                      className="action_button action_tags"
                      onClick={this.showTagGroupsDialog}
                    />
                  </li>
                ) : (
                  <div>
                    {tagGroup !== null ? (
                      <li>
                        <button
                          title="Tags"
                          className="action_button action_tags"
                          onClick={this.showTags}
                        />
                      </li>
                    ) : null}
                  </div>
                )}

                <li>
                  <div className="dropdown">
                    <button title="New" className="action_button action_new" />
                    <div
                      className={
                        dropdown_content_class + " dropdown_content_new"
                      }
                    >
                      <Link
                        to={
                          "/" +
                          (id !== null ? id + "/" : "") +
                          "newTextNote" +
                          (tagGroup !== null && tagGroup !== undefined
                            ? "?tagGroupId=" + tagGroup.id
                            : "")
                        }
                      >
                        Note
                      </Link>
                      <Link
                        to={
                          "/" +
                          (id !== null ? id + "/" : "") +
                          "newChecklistNote" +
                          (tagGroup !== null && tagGroup !== undefined
                            ? "?tagGroupId=" + tagGroup.id
                            : "")
                        }
                      >
                        Checklist
                      </Link>
                      <Link
                        to={"/" + (id !== null ? id + "/" : "") + "newFolder"}
                      >
                        Folder
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </header>

          <div className="custom_container mt-3">
            <div className="table">
              <div className="datatable">
                {isSearchVisible ? (
                  <div className="div_search">
                    <input
                      id="input_search"
                      placeholder="Type to search..."
                      className="input_search"
                      type="text"
                      onChange={this.searching}
                    />
                    <button
                      className="action_button action_close_search"
                      title="Close searchbar"
                      onClick={this.setSearchVisibility}
                    />
                  </div>
                ) : null}

                {showTags ? (
                  <div className="div_tags">
                    <ul>
                      {tags &&
                        tags.map((tag) => (
                          <li
                            onClick={() => this.selectTag(tag.id)}
                            className={
                              selectedTagId === tag.id ? "selected_tag" : ""
                            }
                          >
                            {tag.title}
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : null}

                {hasItems ? (
                  <div>
                    {folders && folders.length > 0 ? (
                      <div>
                        <br />
                        <label className="list_title">Folders</label>

                        <Masonry
                          breakpointCols={breakpointCols}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {folders.map((folder) => (
                            <FolderRow
                              onContextMenuVisibilityChange={
                                this.onContextMenuVisibilityChange
                              }
                              key={folder.id}
                              folder={folder}
                              refresh={this.refresh}
                              getFoldersForMove={this.getFoldersForMove}
                              showNotification={this.showNotification}
                            />
                          ))}
                        </Masonry>
                      </div>
                    ) : null}

                    {pinnedNotes && pinnedNotes.length > 0 ? (
                      <div>
                        <br />
                        <label className="list_title">Pinned notes</label>

                        <Masonry
                          breakpointCols={breakpointCols}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {pinnedNotes.map((note) => (
                            <NoteRow
                              onContextMenuVisibilityChange={
                                this.onContextMenuVisibilityChange
                              }
                              parentId={id}
                              key={note.id}
                              note={note}
                              refresh={this.refresh}
                              onLoadingStateChange={this.onLoadingStateChange}
                              getFoldersForMove={this.getFoldersForMove}
                              showNotification={this.showNotification}
                            />
                          ))}
                        </Masonry>
                      </div>
                    ) : null}

                    {notes && notes.length > 0 ? (
                      <div>
                        <br />
                        <label className="list_title">Notes</label>

                        <Masonry
                          breakpointCols={breakpointCols}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {notes.map((note) => (
                            <NoteRow
                              onContextMenuVisibilityChange={
                                this.onContextMenuVisibilityChange
                              }
                              parentId={id}
                              key={note.id}
                              note={note}
                              refresh={this.refresh}
                              onLoadingStateChange={this.onLoadingStateChange}
                              getFoldersForMove={this.getFoldersForMove}
                              showNotification={this.showNotification}
                            />
                          ))}
                        </Masonry>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {!loadingItems ? (
                      <label className="no_data">Empty</label>
                    ) : null}
                  </div>
                )}

                {this.state.showTagGroupsDialog ? (
                  <ListTagGroupDialogComponent
                    selector={false}
                    onClose={this.onCloseTagGroupsDialog}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
