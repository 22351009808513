import http from "../http-common";

class FolderService {
  get(id) {
    return http.get(`/folders/${id}`);
  }

  create(parentId, data) {
    if (parentId === null) {
      return http.post("/folders", data);
    } else {
      return http.post(`/folders?parentId=${parentId}`, data);
    }
  }

  update(data) {
    return http.put(`/folders`, data);
  }

  updateDestination(data) {
    return http.put("/folders/destination", data);
  }

  delete(id) {
    return http.delete(`/folders/${id}`);
  }
}

export default new FolderService();
