import React, { Component } from "react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DeleteDialog from "../dialog/delete-dialog.component";
import ReminderDialog from "../dialog/reminder-dialog.componet";
import { Link } from "react-router-dom";
import textNoteService from "../../services/text-note.service";
import checklistNoteService from "../../services/checklist-note.service";
import drawingNoteService from "../../services/drawing-note.service";
import ChecklistNoteService from "../../services/checklist-note.service";
import TextNoteService from "../../services/text-note.service";
import DrawingNoteService from "../../services/drawing-note.service";
import methods from "../../methods";
import SelectNewDestinationFolder from "../dialog/select-new-destination-folder.component";

export default class NoteRow extends Component {
  constructor(props) {
    super(props);

    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onCloseDialogSetReminder = this.onCloseDialogSetReminder.bind(this);
    this.onCloseReminderDialog = this.onCloseDialogSetReminder.bind(this);
    this.onCloseSelectDialog = this.onCloseSelectDialog.bind(this);

    this.state = {
      note: this.props.note,
      showDeleteDialog: false,
      reminderText: "",
      showReminderDialog: false,
      humanDate: "",
      parentId: this.props.parentId,
      selectFolders: [],
      showSelectFolderDialog: false,
    };
  }

  onCloseSelectDialog(selected, folderId, folderName) {
    if (selected) {
      const newDestination = {
        id: this.state.note.id,
        destinationId: folderId,
      };

      // eslint-disable-next-line default-case
      switch (this.state.note.type) {
        case "textNote":
          textNoteService
            .updateDestination(newDestination)
            .then(() => {
              this.props.showNotification(
                "info",
                "Note " + this.state.note.title + " was moved to " + folderName
              );
              this.props.refresh();
            })
            .catch((e) => {
              methods.processError(e);
            });
          break;
        case "checklistNote":
          checklistNoteService
            .updateDestination(newDestination)
            .then(() => {
              this.props.refresh();
              this.props.showNotification(
                "info",
                "Note " + this.state.note.title + " was moved to " + folderName
              );
            })
            .catch((e) => {
              methods.processError(e);
            });
          break;
        case "drawingNote":
          drawingNoteService
            .updateDestination(newDestination)
            .then(() => {
              this.props.refresh();
              this.props.showNotification(
                "info",
                "Note " + this.state.note.title + " was moved to " + folderName
              );
            })
            .catch((e) => {
              methods.processError(e);
            });
          break;
      }

      this.props.onContextMenuVisibilityChange(false);

      this.setState({
        showSelectFolderDialog: false,
      });
    }
  }

  componentDidMount() {
    if (this.state.note.reminderDate !== undefined) {
      const dt = this.state.note.reminderDate.split("_");
      const humanDate =
        dt[0] + ":" + dt[1] + " " + dt[2] + "/" + dt[3] + "/" + dt[4];
      const reminderText = "Reminder is set on " + humanDate;
      this.setState({
        reminderText: reminderText,
        humanDate: humanDate,
      });
    }
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      const type = this.state.note.type;
      const id = this.state.note.id;
      switch (type) {
        case "checklistNote":
          ChecklistNoteService.delete(id)
            .then(() => {
              this.props.showNotification(
                "success",
                "Note " + this.state.note.title + " was deleted"
              );
              this.props.refresh();
            })
            .catch((e) => methods.processError(e));
          break;
        case "textNote":
          TextNoteService.delete(id)
            .then(() => {
              this.props.showNotification(
                "success",
                "Note " + this.state.note.title + " was deleted"
              );
              this.props.refresh();
            })
            .catch((e) => methods.processError(e));
          break;
        case "drawingNote":
          DrawingNoteService.delete(id)
            .then(() => {
              this.props.showNotification(
                "success",
                "Note " + this.state.note.title + " was deleted"
              );
              this.props.refresh();
            })
            .catch((e) => methods.processError(e));
          break;
        default:
          break;
      }
    }

    this.setState({
      showDeleteDialog: false,
    });
  }

  onCloseDialogSetReminder(reminderDate) {
    const note = this.state.note;

    note.reminderDate = reminderDate;

    this.updateNote(note);

    this.setState({
      showReminderDialog: false,
    });
  }

  onCloseReminderDialog() {
    this.setState({
      showReminderDialog: false,
    });
  }

  render() {
    const changePinStatus = () => {
      this.props.onLoadingStateChange(true);

      const note = this.state.note;
      const pinned = !note.pinned;

      note.pinned = pinned;

      this.updateNote(note);
    };

    const deleteItem = () => {
      this.setState({
        showDeleteDialog: true,
      });
    };

    const setReminder = () => {
      this.setState({
        showReminderDialog: true,
      });
    };

    const moveNote = () => {
      const folders = this.props.getFoldersForMove(this.state.note.id);
      this.setState(
        {
          selectFolders: folders,
          showSelectFolderDialog: true,
        },
        () => this.props.onContextMenuVisibilityChange(true)
      );
    };

    const note = this.state.note;
    const parentId = this.state.parentId;

    return (
      <div>
        <ContextMenuTrigger id={"ctxMenu" + note.id}>
          <Link
            className={
              note.color !== "default" ? "row background_" + note.color : "row"
            }
            to={
              (parentId === null ? "" : "/" + parentId) +
              "/" +
              note.type +
              "/" +
              note.id
            }
          >
            <div className="item_content">
              {note.title && note.title.length > 0 ? (
                <label className="row_title">{note.title}</label>
              ) : null}
              {note.type === "textNote" &&
              note.shortDescription !== undefined &&
              note.shortDescription.length > 0 ? (
                <div
                  className="row_content"
                  dangerouslySetInnerHTML={{ __html: note.shortDescription }}
                />
              ) : null}

              {note.type === "checklistNote" &&
              note.checklistStatus !== "0/0" ? (
                <div className="checklist">
                  <img
                    className="checklist_img"
                    src={
                      note.checklistImage === "checklist_complete.svg"
                        ? require("../../icons/checklist_complete.svg").default
                        : require("../../icons/checklist_incomplete.svg")
                            .default
                    }
                    alt="Checklist"
                  />
                  <h1 className="checklist_status">{note.checklistStatus}</h1>
                </div>
              ) : null}

              {note.reminderDate !== undefined ? (
                <div className="reminder">
                  <img
                    title={this.state.reminderText}
                    src={require("../../icons/reminder-on.svg").default}
                    alt="Reminder"
                  />
                  <h1>{this.state.humanDate}</h1>
                </div>
              ) : null}

              {note.tag !== undefined && note.tag != null ? (
                <div className="tag">
                  <img
                    title={note.tag.title}
                    src={require("../../icons/tag.svg").default}
                    alt="Tag"
                  />
                  <h1>{note.tag.title}</h1>
                </div>
              ) : null}
            </div>
          </Link>
        </ContextMenuTrigger>

        <ContextMenu
          onShow={() => this.props.onContextMenuVisibilityChange(true)}
          onHide={() => this.props.onContextMenuVisibilityChange(false)}
          id={"ctxMenu" + this.state.note.id}
        >
          <MenuItem onClick={moveNote}>
            <span>Move note</span>
          </MenuItem>
          <MenuItem onClick={deleteItem}>
            <span>Delete</span>
          </MenuItem>
        </ContextMenu>

        {this.state.showDeleteDialog ? (
          <DeleteDialog onDeleteConfirm={this.onDeleteConfirm} />
        ) : null}

        {this.state.showReminderDialog ? (
          <ReminderDialog
            reminderDate={note.reminderDate}
            onCloseDialogSetReminder={this.onCloseDialogSetReminder}
            onCloseDialog={this.onCloseReminderDialog}
          />
        ) : null}

        {this.state.showSelectFolderDialog ? (
          <SelectNewDestinationFolder
            folders={this.state.selectFolders}
            onClose={this.onCloseSelectDialog}
          />
        ) : null}
      </div>
    );
  }

  updateNote(note) {
    // eslint-disable-next-line default-case
    switch (note.type) {
      case "textNote":
        textNoteService
          .update(note)
          .then(() => {
            this.props.showNotification(
              "info",
              "Note " + this.state.note.title + " was updated"
            );
            this.props.refresh();
          })
          .catch((e) => {
            methods.processError(e);
          });
        break;
      case "checklistNote":
        checklistNoteService
          .update(note)
          .then(() => {
            this.props.refresh();
            this.props.showNotification(
              "info",
              "Note " + this.state.note.title + " was updated"
            );
          })
          .catch((e) => {
            methods.processError(e);
          });
        break;
      case "drawingNote":
        drawingNoteService
          .update(note)
          .then(() => {
            this.props.refresh();
            this.props.showNotification(
              "info",
              "Note " + this.state.note.title + " was updated"
            );
          })
          .catch((e) => {
            methods.processError(e);
          });
        break;
    }
  }
}
