import React, { Component } from "react";

export default class ColorPickerDiv extends Component {
  constructor(props) {
    super(props);
    this.changeColor = this.changeColor.bind(this);

    this.state = {
      selectedColor: "default",
      isNote: false,
    };
  }

  componentDidMount() {
    this.setState({
      selectedColor: this.props.selectedColor,
      isNote: this.props.isNote,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedColor !== this.props.selectedColor) {
      this.setState({
        selectedColor: this.props.selectedColor,
      });
    }
  }

  changeColor(color) {
    this.props.changeColor(color);
  }

  render() {
    return (
      <div
        className={
          this.state.isNote ? "div_color_picker_note" : "div_color_picker"
        }
      >
        {this.state.selectedColor === "default" ? (
          <button
            title="Default"
            className="select_color_button_default selected_default"
          />
        ) : (
          <button
            title="Default"
            className="select_color_button_default select_default"
            onClick={() => this.changeColor("default")}
          />
        )}

        {this.state.selectedColor === "red" ? (
          <button title="Red" className="select_color_button selected_red" />
        ) : (
          <button
            title="Red"
            className="select_color_button select_red"
            onClick={() => this.changeColor("red")}
          />
        )}

        {this.state.selectedColor === "pink" ? (
          <button title="Pink" className="select_color_button selected_pink" />
        ) : (
          <button
            title="Pink"
            className="select_color_button select_pink"
            onClick={() => this.changeColor("pink")}
          />
        )}

        {this.state.selectedColor === "purple" ? (
          <button
            title="Purple"
            className="select_color_button selected_purple"
          />
        ) : (
          <button
            title="Purple"
            className="select_color_button select_purple"
            onClick={() => this.changeColor("purple")}
          />
        )}

        {this.state.selectedColor === "indigo" ? (
          <button
            title="Indigo"
            className="select_color_button selected_indigo"
          />
        ) : (
          <button
            title="Indigo"
            className="select_color_button select_indigo"
            onClick={() => this.changeColor("indigo")}
          />
        )}

        {this.state.selectedColor === "blue" ? (
          <button title="Blue" className="select_color_button selected_blue" />
        ) : (
          <button
            title="Blue"
            className="select_color_button select_blue"
            onClick={() => this.changeColor("blue")}
          />
        )}

        {this.state.selectedColor === "teal" ? (
          <button title="Teal" className="select_color_button selected_teal" />
        ) : (
          <button
            title="Teal"
            className="select_color_button select_teal"
            onClick={() => this.changeColor("teal")}
          />
        )}

        {this.state.selectedColor === "green" ? (
          <button
            title="Green"
            className="select_color_button selected_green"
          />
        ) : (
          <button
            title="Green"
            className="select_color_button select_green"
            onClick={() => this.changeColor("green")}
          />
        )}

        {this.state.selectedColor === "yellow" ? (
          <button
            title="Yellow"
            className="select_color_button selected_yellow"
          />
        ) : (
          <button
            title="Yellow"
            className="select_color_button select_yellow"
            onClick={() => this.changeColor("yellow")}
          />
        )}

        {this.state.selectedColor === "amber" ? (
          <button
            title="Amber"
            className="select_color_button selected_amber"
          />
        ) : (
          <button
            title="Amber"
            className="select_color_button select_amber"
            onClick={() => this.changeColor("amber")}
          />
        )}

        {this.state.selectedColor === "orange" ? (
          <button
            title="Orange"
            className="select_color_button selected_orange"
          />
        ) : (
          <button
            title="Orange"
            className="select_color_button select_orange"
            onClick={() => this.changeColor("orange")}
          />
        )}

        {this.state.selectedColor === "brown" ? (
          <button
            title="Brown"
            className="select_color_button selected_brown"
          />
        ) : (
          <button
            title="Brown"
            className="select_color_button select_brown"
            onClick={() => this.changeColor("brown")}
          />
        )}

        {this.state.selectedColor === "blueGray" ? (
          <button
            title="Blue Gray"
            className="select_color_button selected_blueGray"
          />
        ) : (
          <button
            title="Blue Gray"
            className="select_color_button select_blueGray"
            onClick={() => this.changeColor("blueGray")}
          />
        )}
      </div>
    );
  }
}
