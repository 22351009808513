import React, { Component } from "react";
import FolderService from "../../services/folder.service";
import CookieHelper from "../../cookie-helper";
import DeleteDialog from "../dialog/delete-dialog.component";
import ColorPickerDiv from "../general/div-color-picker.component";
import ListTagGroupDialogComponent from "../tag/list-tag-group-dialog.component";
import { Navigate } from "react-router-dom";
import methods from "../../methods";
import reportVisitService from "../../reporing-visit/report-visit.service";

export default class FolderDialog extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.getFolder = this.getFolder.bind(this);
    this.onKeyDownTitle = this.onKeyDownTitle.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.initBackgroundSync = this.initBackgroundSync.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.showTagGroups = this.showTagGroups.bind(this);
    this.onClickTagGroup = this.onClickTagGroup.bind(this);
    this.onCloseTagGroupsDialog = this.onCloseTagGroupsDialog.bind(this);
    this.navigateTo = this.navigateTo.bind(this);

    this.state = {
      goToId: "",
      folder: {},
      change: false,
      tempChecklistTitle: "",
      loadingFolder: false,
      urls: [],
      emails: [],
      showDeleteDialog: false,
      view: "note",
      syncIntervalId: "",
      parentId: null,
      canShowTagGroups: false,
      navigateToPath: "",
      closeDialog: false,
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;

    if (pathname.includes("newFolder")) {
      let parentId = null;
      if (!pathname.startsWith("/newFolder")) {
        parentId = pathname.split("/")[1];
      }

      const tempFolder = {
        title: "",
        color: "default",
      };

      this.setState({
        parentId: parentId,
        folder: tempFolder,
        change: true,
      });
      this.initBackgroundSync();
    } else {
      let id;

      if (pathname.startsWith("/folder")) {
        id = pathname.split("/")[2];
        this.setState({
          goToId: "",
        });
      } else {
        id = pathname.split("/")[3];
        this.setState({
          goToId: pathname.split("/")[1],
        });
      }
      this.getFolder(id);
    }
    document.addEventListener("keydown", this.handleEscapeKey, false);
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
    document.removeEventListener("keydown", this.handleEscapeKey, false);
  }

  getFolder(id) {
    this.setState({
      loadingFolder: true,
    });
    FolderService.get(id)
      .then((response) => {
        this.initBackgroundSync();

        this.setState({
          folder: response.data,
          loadingFolder: false,
          change: false,
        });

        CookieHelper.extendValidity();

        this.changeColor(this.state.folder.color);
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  onChangeTitle(e) {
    const newTitle = e.target.value;
    this.setState((prevState) => {
      return {
        folder: {
          ...prevState.folder,
          title: newTitle,
        },
        change: true,
      };
    });
  }

  save(event, isShortcut) {
    if (isShortcut || event.target === event.currentTarget) {
      if (this.state.folder.title.length === 0) {
        this.navigateTo("/" + this.state.goToId);
      }

      this.setState({
        loadingFolder: true,
      });

      const goToId = this.state.goToId;
      const folder = this.state.folder;

      if (folder.id === undefined) {
        FolderService.create(this.state.parentId, folder)
          .then((response) => {
            this.navigateTo("/" + response.data.id);
          })
          .catch((e) => {
            methods.processError(e);
          });
      } else {
        FolderService.update(folder)
          .then(() => {
            this.navigateTo("/" + goToId);
          })
          .catch((e) => {
            methods.processError(e);
          });
      }
    }
  }

  delete() {
    if (this.state.folder.id === undefined) {
      const parentId = this.state.parentId;
      if (parentId !== null) {
        this.navigateTo("/" + parentId);
      } else {
        this.navigateTo("/");
      }
    } else {
      this.setState({
        showDeleteDialog: true,
      });
    }
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      const folderId = this.state.parentId;
      const id = this.state.folder.id;
      if (id === undefined) {
        if (folderId !== null) {
          this.navigateTo("/" + folderId);
        } else {
          this.navigateTo("/");
        }
      } else {
        FolderService.delete(id)
          .then(() => {
            if (folderId !== null) {
              this.navigateTo("/" + folderId);
            } else {
              this.navigateTo("/");
            }
          })
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      this.setState({
        showDeleteDialog: false,
      });
    }
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      if (this.state.showDeleteDialog) {
        this.setState({
          showDeleteDialog: false,
        });
      } else {
        this.save(null, true);
      }
    } else if (e.key === "Delete" && e.ctrlKey) {
      this.delete();
    } else if (e.key.toLowerCase() === "s" && e.ctrlKey) {
      e.preventDefault();
      this.save(null, true);
    } else if (e.key.toLowerCase() === "t" && e.ctrlKey) {
      e.preventDefault();
      this.showTagGroups();
    }
  }

  onKeyDownTitle = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  onViewTypeChange = (view) => {
    this.setState({
      view: view,
    });
  };

  changeColor = (colorToChange) => {
    var tempBackgroundClass, tempHeaderBackgroundClass;

    if (colorToChange !== "default") {
      tempBackgroundClass = "background_" + colorToChange;
      tempHeaderBackgroundClass = "background_" + colorToChange;
    } else {
      tempBackgroundClass = "";
      tempHeaderBackgroundClass = "";
    }

    this.setState((prevState) => {
      return {
        folder: {
          ...prevState.folder,
          color: colorToChange,
        },
        background_color_class: tempBackgroundClass,
        header_background_color: tempHeaderBackgroundClass,
        change: true,
      };
    });
  };

  backgroundSync() {
    const folder = this.state.folder;

    if (this.state.change) {
      if (this.state.folder.title.length === 0) {
        return;
      }
      this.setState({
        change: false,
      });

      if (folder.id === undefined) {
        FolderService.create(this.state.parentId, folder)
          .then((response) => {
            this.setState((prevState) => {
              return {
                folder: {
                  ...prevState.folder,
                  id: response.data.id,
                },
                goToId: response.data.id,
              };
            });
          })
          .catch((e) => {
            methods.processError(e);
          });
      } else {
        FolderService.update(folder).catch((e) => {
          methods.processError(e);
        });
      }
    } else {
      FolderService.get(folder.id)
        .then((response) => {
          if (response.data === "") {
            if (this.state.parentId !== null) {
              this.navigateTo("/" + this.state.parentId);
            } else {
              this.navigateTo("/");
            }
          } else {
            const tempChange = this.state.change;
            this.changeColor(response.data.color);
            this.setState({
              folder: response.data,
              change: tempChange,
            });
          }
        })
        .catch((e) => {
          methods.processError(e);
        });
    }
  }

  initBackgroundSync() {
    const intervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);
    this.setState({
      syncIntervalId: intervalId,
    });
  }

  showTagGroups() {
    this.setState({
      canShowTagGroups: true,
    });
  }

  onClickTagGroup(tagGroup) {
    this.setState((prevState) => {
      return {
        folder: {
          ...prevState.folder,
          tagGroup: tagGroup,
        },
        change: true,
        canShowTagGroups: false,
      };
    });
  }

  onCloseTagGroupsDialog() {
    this.setState({
      canShowTagGroups: false,
    });
  }

  navigateTo(pathname) {
    this.setState({
      navigateToPath: pathname,
      closeDialog: true,
    });
  }

  render() {
    let loadingFolder = this.state.loadingFolder;
    let folder = this.state.folder;
    let tagGroup = folder.tagGroup;
    let navigateToPath = this.state.navigateToPath;
    let closeDialog = this.state.closeDialog;

    return (
      <div
        className="dialog_wrapper"
        onClick={(event) => this.save(event, false)}
      >
        {loadingFolder ? <div id="loader" /> : null}
        <div className="dialog folder_dialog">
          <div
            className={
              "dialog_body_folder_background " +
              this.state.background_color_class
            }
          >
            <div className="dialog_header">
              <div className="dialog_title_folder">
                <button
                  title="Close"
                  className="action_button action_close"
                  onClick={(event) => this.save(event)}
                />
                <input
                  maxLength={50}
                  placeholder="Title"
                  className="edit_title_folder"
                  type="text"
                  value={folder.title}
                  onKeyDown={this.onKeyDownTitle}
                  onChange={this.onChangeTitle}
                />
              </div>

              <nav>
                <ul>
                  <li>
                    <button
                      title="Delete"
                      className="action_button action_delete"
                      onClick={this.delete}
                    />
                  </li>
                </ul>
              </nav>
            </div>

            {tagGroup ? (
              <div className="div_tag_group" title="Select tag group">
                <img
                  title="Select tag group"
                  src={require("../../icons/tag_multiple.svg").default}
                  height="30"
                  width="30"
                  alt="Select tag group"
                  style={{ margin: "10px" }}
                  onClick={this.showTagGroups}
                />
                <label onClick={this.showTagGroups}>{tagGroup.title}</label>
              </div>
            ) : (
              <div className="div_tag_group" title="Select tag group">
                <img
                  title="Select tag group"
                  src={require("../../icons/tag_multiple_outline.svg").default}
                  height="30"
                  width="30"
                  alt="Select tag group"
                  style={{ margin: "10px" }}
                  onClick={this.showTagGroups}
                />
                <label onClick={this.showTagGroups}>No tag group</label>
              </div>
            )}

            <ColorPickerDiv
              selectedColor={folder.color}
              changeColor={this.changeColor}
              isNote={false}
            />
          </div>
        </div>

        {this.state.canShowTagGroups ? (
          <ListTagGroupDialogComponent
            selector={true}
            onClick={this.onClickTagGroup}
            onClose={this.onCloseTagGroupsDialog}
          />
        ) : null}

        {this.state.showDeleteDialog ? (
          <DeleteDialog onDeleteConfirm={this.onDeleteConfirm} type="folder" />
        ) : null}

        {closeDialog ? <Navigate to={navigateToPath} replace={true} /> : null}
      </div>
    );
  }
}
