import axios from "axios";
import cookieHelper from "./cookie-helper";

const baseURL = "https://api.notes.davidsimak.dev";
// const baseURL = "http://localhost:8090";

export default axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    session: cookieHelper.get(),
  },
});
