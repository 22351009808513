import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ItemsComponent from "./components/main-list.component";
import TextNoteDialog from "./components/note-editors/text-note-editor.component";
import ChecklistNoteDialog from "./components/note-editors/checklist-note-editor.component";
import DrawingNoteDialog from "./components/note-editors/drawing-note-editor.component";
import FolderDialog from "./components/folder/folder-dialog.component";

import ItemsService from "./services/items.service";

import sound from "./sounds/alarm_sound1.wav";
import methods from "./methods";

import reportVisitService from "./reporing-visit/report-visit.service";

class App extends Component {
  constructor(props) {
    super(props);

    this.firstCheckForReminders = this.firstCheckForReminders.bind(this);
    this.firstNextMinuteCheckForReminders =
      this.firstNextMinuteCheckForReminders.bind(this);
    this.nextMinutesCheckForReminders =
      this.nextMinutesCheckForReminders.bind(this);
    this.askForReminders = this.askForReminders.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.beep = this.beep.bind(this);

    this.state = {
      intervalId: "",
      timeoutId: "",
    };
  }

  componentDidMount() {
    if (Notification.permission === "granted") {
      this.firstNextMinuteCheckForReminders();
      this.firstCheckForReminders();
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.firstNextMinuteCheckForReminders();
          this.firstCheckForReminders();
        }
      });
    }

    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearTimeout(this.state.timeoutId);
  }

  beep() {
    new Audio(sound).play();
  }

  firstCheckForReminders() {
    this.askForReminders();
  }

  askForReminders() {
    const date = new Date();

    let sMin = date.getMinutes().toString();
    if (sMin.length === 1) {
      sMin = "0" + sMin;
    }

    const reminderDate =
      date.getHours() +
      "_" +
      sMin +
      "_" +
      date.getDate() +
      "_" +
      (date.getMonth() + 1) +
      "_" +
      date.getFullYear();

    ItemsService.getReminders(reminderDate)
      .then((response) => {
        const notes = response.data;
        notes.forEach((note) => {
          this.showNotification(note);
        });
      })
      .catch((error) => {
        methods.processError(error);
      });
  }

  showNotification(note) {
    let notification;
    this.beep();

    let title = "";

    if (note.title !== undefined) {
      title = note.title;
    }

    if (note.type === "textNote") {
      let content = "";
      if (note.shortDescription !== undefined && note.shortDescription) {
        var html = note.shortDescription.replaceAll("<br>", "\n");
        var div = document.createElement("div");
        div.innerHTML = html;
        content = div.textContent || div.innerText || "";
      }

      notification = new Notification(title, {
        body: content,
        icon: require("./icons/icon.png").default,
      });
    } else {
      notification = new Notification(title, {
        icon: require("./icons/icon.png").default,
      });
    }

    notification.onclick = () => {
      window.location.hash = "#/" + note.type + "/" + note.id;
    };
  }

  firstNextMinuteCheckForReminders() {
    const nextReminder =
      60000 -
      new Date().getSeconds() * 1000 -
      new Date().getMilliseconds() +
      200;
    const timeoutId = setTimeout(() => {
      this.nextMinutesCheckForReminders();
      this.askForReminders();
    }, nextReminder);

    this.setState({
      timeoutId: timeoutId,
    });
  }

  nextMinutesCheckForReminders() {
    const intervalId = setInterval(() => {
      this.askForReminders();
    }, 60000);

    this.setState({
      intervalId: intervalId,
    });
  }

  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<ItemsComponent />}>
            <Route path="newFolder" element={<FolderDialog />} />
            <Route path="folder/:folderId" element={<FolderDialog />} />
          </Route>

          <Route path="/newTextNote" element={<TextNoteDialog />} />
          <Route path="/textNote/:noteId" element={<TextNoteDialog />} />

          <Route path="/newChecklistNote" element={<ChecklistNoteDialog />} />
          <Route
            path="/checklistNote/:noteId"
            element={<ChecklistNoteDialog />}
          />

          <Route path="/drawingNote/:noteId" element={<DrawingNoteDialog />} />

          <Route exact path="/:parentFolderId" element={<ItemsComponent />}>
            <Route exact path="newFolder" element={<FolderDialog />} />
            <Route exact path="folder/:folderId" element={<FolderDialog />} />
          </Route>

          <Route
            exact
            path="/:parentFolderId/newTextNote"
            element={<TextNoteDialog />}
          />
          <Route
            exact
            path="/:parentFolderId/textNote/:noteId"
            element={<TextNoteDialog />}
          />

          <Route
            exact
            path="/:parentFolderId/newChecklistNote"
            element={<ChecklistNoteDialog />}
          />
          <Route
            exact
            path="/:parentFolderId/checklistNote/:noteId"
            element={<ChecklistNoteDialog />}
          />

          <Route
            exact
            path="/:parentFolderId/drawingNote/:noteId"
            element={<DrawingNoteDialog />}
          />
        </Routes>
      </div>
    );
  }
}

export default App;
