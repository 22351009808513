import http from "../http-common";

class TextNoteService {
  get(id) {
    return http.get(`/textNotes/${id}`);
  }

  create(parentId, data) {
    if (parentId === null) {
      return http.post("/textNotes", data);
    } else {
      return http.post(`/textNotes?parentId=${parentId}`, data);
    }
  }

  update(data) {
    return http.put(`/textNotes`, data);
  }

  updateDestination(data) {
    return http.put("textNotes/destination", data);
  }

  delete(id) {
    return http.delete(`/textNotes/${id}`);
  }
}

export default new TextNoteService();
