import React, { Component } from "react";
import TagService from "../../services/tags.service";
import methods from "../../methods";

export default class ChooseTagDialogComponent extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.clickTag = this.clickTag.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);

    this.state = {
      tagGroupTitle: null,
      tags: [],
      loadingTags: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey, false);
    this.setState({
      loadingTags: true,
    });
    TagService.get(this.props.tagGroupId)
      .then((response) => {
        this.setState({
          loadingTags: false,
          tagGroupTitle: response.data.title,
          tags: response.data.tags,
        });
      })
      .catch((error) => {
        methods.processError(error);
      });
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      this.close();
    }
  }

  clickTag(tag) {
    this.props.onClick(tag);
  }

  close() {
    this.props.onClose();
  }

  render() {
    return (
      <div className="dialog_wrapper">
        {this.state.loadingTags ? <div id="loader2" /> : null}

        <div className="dialog item_dialog">
          <div className="dialog_header background_default">
            <div className="dialog_title_folder">
              <button
                title="Close"
                className="action_button action_close"
                onClick={this.close}
              />
              <input
                maxLength={50}
                placeholder="Title"
                disabled
                className="edit_title_folder"
                type="text"
                value={this.state.tagGroupTitle}
              />
            </div>
          </div>

          <div className="dialog_container tag_dialog_container">
            <div className="outer_container_scroll tag_scroll">
              <div className="inner_container_scroll">
                <div className="tag_row" onClick={() => this.clickTag(null)}>
                  <label className="tag_row_title">None</label>
                </div>

                {this.state.tags &&
                  this.state.tags.map((tag, index) => (
                    <div
                      className="tag_row"
                      key={tag.id}
                      onClick={() => this.clickTag(tag)}
                    >
                      <label className="tag_row_title">{tag.title}</label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
