import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimeKeeper from "react-timekeeper";

export default class ReminderDialog extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.delete = this.delete.bind(this);
    this.save = this.save.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onViewChange = this.onViewChange.bind(this);

    this.state = {
      reminderDate: this.props.reminderDate,
      orgReminderDate: this.props.reminderDate,
      reminderDateHuman: "",
      time: new Date().getHours() + ":" + new Date().getMinutes(),
      date:
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      dateD: new Date(),
      view: "time",
      showErrorMessage: false,
    };
  }

  componentDidMount() {
    if (this.state.reminderDate !== null) {
      const dt = this.state.reminderDate.split("_");
      const humanDate =
        dt[0] + ":" + dt[1] + " " + dt[2] + "/" + dt[3] + "/" + dt[4];
      this.setState({
        time: dt[0] + ":" + dt[1],
        date: dt[2] + "/" + dt[3] + "/" + dt[4],
        dateD: new Date(Number(dt[4]), Number(dt[3]) - 1, Number(dt[2])),
        reminderDateHuman: humanDate,
      });
    }
  }

  onDateChange(date) {
    const cDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    const dateHuman = this.state.time + " " + cDate;
    const reminderDate =
      this.state.time.split(":")[0] +
      "_" +
      this.state.time.split(":")[1] +
      "_" +
      date.getDate() +
      "_" +
      (date.getMonth() + 1) +
      "_" +
      date.getFullYear();
    this.setState({
      date: cDate,
      reminderDate: reminderDate,
      reminderDateHuman: dateHuman,
      dateD: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    });
  }

  onTimeChange(time) {
    const dateHuman = time.formatted24 + " " + this.state.date;
    const sTime = time.formatted24.split(":");
    const sDate = this.state.date.split("/");
    const reminderDate =
      sTime[0] +
      "_" +
      sTime[1] +
      "_" +
      sDate[0] +
      "_" +
      sDate[1] +
      "_" +
      sDate[2];

    this.setState({
      time: time.formatted24,
      reminderDate: reminderDate,
      reminderDateHuman: dateHuman,
    });
  }

  onViewChange = (view) => {
    this.setState({
      view: view,
    });
  };

  save() {
    if (this.state.time !== "" && this.state.date !== "") {
      this.props.onCloseDialogSetReminder(this.state.reminderDate);
    } else {
      this.setState({
        showErrorMessage: true,
      });
    }
  }

  close() {
    this.props.onCloseDialog();
  }

  delete() {
    this.props.onCloseDialogSetReminder(null);
  }

  render() {
    return (
      <div className="dialog_wrapper">
        <div className="dialog reminder_dialog">
          <h1>Set reminder</h1>

          {this.state.reminderDate === null ? (
            <p>Reminder is not set.</p>
          ) : null}

          {this.state.reminderDate !== null &&
          this.state.reminderDate === this.state.orgReminderDate ? (
            <p>Reminder is set on {this.state.reminderDateHuman}</p>
          ) : null}

          {this.state.reminderDate !== null &&
          this.state.reminderDate !== this.state.orgReminderDate ? (
            <p>Reminder will be set on {this.state.reminderDateHuman}</p>
          ) : null}

          <div className="top_panel_reminder">
            <table>
              <th width="45%">
                <button
                  className={
                    "top_panel_reminder_button" +
                    (this.state.view === "time"
                      ? " reminder_active_button"
                      : "")
                  }
                  onClick={() => this.onViewChange("time")}
                >
                  Time
                </button>
              </th>
              <th width="10%" />
              <th width="45%">
                <button
                  className={
                    "top_panel_reminder_button" +
                    (this.state.view === "date"
                      ? " reminder_active_button"
                      : "")
                  }
                  onClick={() => this.onViewChange("date")}
                >
                  Date
                </button>
              </th>
            </table>
          </div>

          {this.state.view === "time" ? (
            <div className="div_time">
              <TimeKeeper
                onChange={this.onTimeChange}
                time={this.state.time}
                hour24Mode
                switchToMinuteOnHourSelect
              />
            </div>
          ) : null}

          {this.state.view === "date" ? (
            <div className="div_date">
              <Calendar
                minDate={new Date()}
                onChange={this.onDateChange}
                value={this.state.dateD}
              />
            </div>
          ) : null}

          {this.state.showErrorMessage ? (
            <p className="error_p">
              To set reminder, you must select date and time!
            </p>
          ) : null}

          <div className="div_dialog_buttons">
            <div className="div_dialog_button">
              <button className="dialog_confirm" onClick={this.save}>
                Save
              </button>
            </div>
            <div className="div_dialog_button">
              <button className="dialog_close" onClick={this.delete}>
                Delete
              </button>
            </div>
          </div>

          <button className="dialog_button_transparent" onClick={this.close}>
            Close
          </button>
        </div>
      </div>
    );
  }
}
