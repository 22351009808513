import React, { Component } from "react";
import TextNoteService from "../../services/text-note.service";
import CookieHelper from "../../cookie-helper";
import Methods from "../../methods";
import DeleteDialog from "../dialog/delete-dialog.component";
import ReminderDialog from "../dialog/reminder-dialog.componet";
import ContentEditable from "react-contenteditable";
import ColorPickerDiv from "../general/div-color-picker.component";
import ChooseTagDialogComponent from "../tag/choose-tag-dialog.component";
import { Navigate } from "react-router-dom";
import methods from "../../methods";
import reportVisitService from "../../reporing-visit/report-visit.service";

export default class TextNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.getNote = this.getNote.bind(this);
    this.onKeyDownTitle = this.onKeyDownTitle.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.pin = this.pin.bind(this);
    this.setPinState = this.setPinState.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.showReminderDialog = this.showReminderDialog.bind(this);
    this.onCloseReminderDialog = this.onCloseReminderDialog.bind(this);
    this.onCloseDialogSetReminder = this.onCloseDialogSetReminder.bind(this);
    this.setReminderState = this.setReminderState.bind(this);
    this.initBackgroundSync = this.initBackgroundSync.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.getActiveCommands = this.getActiveCommands.bind(this);
    this.backgroundActiveCommands = this.backgroundActiveCommands.bind(this);
    this.getCaretIndex = this.getCaretIndex.bind(this);
    this.setCommand = this.setCommand.bind(this);
    this.showTagsDialog = this.showTagsDialog.bind(this);
    this.onSelectedTag = this.onSelectedTag.bind(this);
    this.onCloseTagsDialog = this.onCloseTagsDialog.bind(this);

    this.state = {
      note: {},
      change: false,
      loadingNote: false,
      div_content_class: "div_content div_content_default",
      pin_class: "action_button action_unpinned",
      bold_class: "rte_button_div",
      underline_class: "rte_button_div",
      italic_class: "rte_button_div",
      strike_throught_class: "rte_button_div",
      indent_class: "rte_button_div",
      outdent_class: "rte_button_div",
      bullet_list_class: "rte_button_div",
      numbered_list_class: "rte_button_div",
      align_center_class: "rte_button_div",
      align_right_class: "rte_button_div",
      align_left_class: "rte_button_div",
      reminder_title: "Not set",
      pin_title: "",
      urls: [],
      emails: [],
      showDeleteDialog: false,
      showReminderDialog: false,
      view: "note",
      links: false,
      syncIntervalId: "",
      caretIntervalId: "",
      parentId: null,
      background_color_class: "",
      canShowTagsDialog: false,
      tagGroupId: null,
      closeDialog: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes("newTextNote")) {
      let folderId = null;
      let tagGroupId = null;

      if (!window.location.pathname.startsWith("/newTextNote")) {
        folderId = window.location.pathname.split("/")[1];
      }

      if (window.location.search !== "") {
        tagGroupId = window.location.search.replace("?tagGroupId=", "");
      }

      const tempNote = {
        title: "",
        content: "",
        color: "default",
        pinned: false,
        reminderDate: null,
      };

      this.setState({
        note: tempNote,
        change: true,
        parentId: folderId,
        tagGroupId: tagGroupId,
      });

      this.initBackgroundSync();
      this.backgroundActiveCommands();
      document.title = "New note | Notes";
    } else {
      let id = "";
      let parentId = null;
      const pathnameSplit = window.location.pathname.split("/");

      if (window.location.pathname.startsWith("/textNote")) {
        id = pathnameSplit[2];
      } else {
        id = pathnameSplit[3];
        parentId = pathnameSplit[1];
      }

      this.getNote(id);

      this.setState({
        parentId: parentId,
      });
    }
    document.addEventListener("keydown", this.handleEscapeKey, false);
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
    clearInterval(this.state.caretIntervalId);
    document.removeEventListener("keydown", this.handleEscapeKey, false);
  }

  getNote(id) {
    this.setState({
      loadingNote: true,
    });
    TextNoteService.get(id)
      .then((response) => {
        this.initBackgroundSync();
        CookieHelper.extendValidity();

        const note = response.data;

        this.setReminderState(note.reminderDate);
        this.changeColor(note.color);
        this.setPinState(note.pinned);

        const data = Methods.getURLsAndEmails(note.content);
        let tempEmails = data.emails;
        let tempUrls = data.urls;

        this.setState({
          note: note,
          urls: tempUrls,
          emails: tempEmails,
          tagGroupId: note.tagGroupId,
          change: false,
          loadingNote: false,
        });

        document.title = note.title + " | Notes";
        this.backgroundActiveCommands();
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  onChangeTitle(e) {
    const newTitle = e.target.value;
    document.title = newTitle + " | Notes";
    this.setState((prevState) => {
      return {
        note: {
          ...prevState.note,
          title: newTitle,
        },
        change: true,
      };
    });
  }

  onChangeDescription = (event) => {
    const newDescription = event.target.value;

    this.getActiveCommands();

    if (newDescription !== this.state.note.content) {
      const data = Methods.getURLsAndEmails(newDescription);
      this.setState((prevState) => {
        return {
          note: {
            ...prevState.note,
            content: newDescription,
          },
          change: true,
          emails: data.emails,
          urls: data.urls,
        };
      });
    }
  };

  backgroundActiveCommands() {
    const intervalId = setInterval(() => {
      this.getActiveCommands();
    }, 300);
    this.setState({
      caretIntervalId: intervalId,
    });
  }

  getCaretIndex(element) {
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  }

  setCommand(command, value) {
    const rte_editor = document.getElementById("rte_editor");
    document.execCommand(command, false, value);
    rte_editor.focus();
  }

  getActiveCommands() {
    let bold_class = "rte_button_div",
      underline_class = "rte_button_div",
      italic_class = "rte_button_div",
      strike_throught_class = "rte_button_div",
      bullet_list_class = "rte_button_div",
      numbered_list_class = "rte_button_div",
      align_center_class = "rte_button_div",
      align_right_class = "rte_button_div",
      align_left_class = "rte_button_div";

    const rte_indent = document.getElementById("rte_indent"),
      rte_outdent = document.getElementById("rte_outdent"),
      rte_font_size = document.getElementById("rte_font_size");

    if (rte_indent !== null) {
      rte_indent.disabled = true;
      rte_outdent.disabled = true;
    }

    if (document.queryCommandState("bold")) {
      bold_class += " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("italic")) {
      italic_class += " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("strikeThrough")) {
      strike_throught_class +=
        " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("underline")) {
      underline_class +=
        " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("insertUnorderedList")) {
      bullet_list_class +=
        " rte_button_active " + this.state.background_color_class;
      rte_indent.disabled = false;
      rte_outdent.disabled = false;
    }

    if (document.queryCommandState("insertOrderedList")) {
      numbered_list_class +=
        " rte_button_active " + this.state.background_color_class;
      rte_indent.disabled = false;
      rte_outdent.disabled = false;
    }

    if (document.queryCommandState("justifyLeft")) {
      align_left_class +=
        " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("justifyCenter")) {
      align_center_class +=
        " rte_button_active " + this.state.background_color_class;
    }

    if (document.queryCommandState("justifyRight")) {
      align_right_class +=
        " rte_button_active " + this.state.background_color_class;
    }

    if (rte_font_size !== null) {
      if (
        document.queryCommandValue("FontSize") !== rte_font_size.value &&
        document.queryCommandValue("FontSize") !== ""
      ) {
        rte_font_size.value = document.queryCommandValue("FontSize");
      } else {
        if (
          document.queryCommandValue("FontSize") === "" &&
          rte_font_size.value !== 3
        )
          rte_font_size.value = 3;
      }
    }

    this.setState({
      bold_class: bold_class,
      underline_class: underline_class,
      italic_class: italic_class,
      strike_throught_class: strike_throught_class,
      bullet_list_class: bullet_list_class,
      numbered_list_class: numbered_list_class,
      align_center_class: align_center_class,
      align_right_class: align_right_class,
      align_left_class: align_left_class,
    });
  }

  pin() {
    const newPinnedState = !this.state.note.pinned;
    this.setPinState(newPinnedState);
    this.setState((prevState) => {
      return {
        note: {
          ...prevState.note,
          pinned: newPinnedState,
        },
        change: true,
      };
    });
  }

  setPinState(pinned) {
    const pinClass = this.state.pin_class;
    let newPinClass = pinClass;
    let pinTitle = "Pin note";

    if (pinned && pinClass.includes("unpinned")) {
      newPinClass = pinClass.replace("unpinned", "pinned");
      pinTitle = "Unpin note";
    } else if (!pinned && pinClass.includes("_pinned")) {
      newPinClass = pinClass.replace("_pinned", "_unpinned");
      pinTitle = "Pin note";
    }

    this.setState({
      pin_class: newPinClass,
      pin_title: pinTitle,
    });
  }

  save(event, isShortcut) {
    if (isShortcut || event.target === event.currentTarget) {
      const note = this.state.note;
      const parentId = this.state.parentId;

      if (note.title.length > 0 || note.content.length > 0) {
        this.setState({
          loadingNote: true,
        });

        if (note.id === undefined) {
          TextNoteService.create(parentId, note)
            .then(() => {
              this.setState({
                closeDialog: true,
              });
            })
            .catch((e) => {
              methods.processError(e);
            });
        } else {
          TextNoteService.update(note)
            .then(() => {
              this.setState({
                closeDialog: true,
              });
            })
            .catch((e) => {
              methods.processError(e);
            });
        }
      } else {
        this.setState({
          closeDialog: true,
        });
      }
    }
  }

  delete() {
    if (this.state.note.id === undefined) {
      this.setState({
        closeDialog: true,
      });
    } else {
      this.setState({
        showDeleteDialog: true,
      });
    }
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      const folderId = this.state.parentId;
      const id = this.state.note.id;
      if (id === undefined) {
        this.setState({
          closeDialog: true,
        });
      } else {
        TextNoteService.delete(id)
          .then(() => {
            this.setState({
              closeDialog: true,
            });
          })
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      this.setState({
        showDeleteDialog: false,
      });
    }
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      if (this.state.showDeleteDialog) {
        this.setState({
          showDeleteDialog: false,
        });
      } else {
        this.save(null, true);
      }
    }

    if (e.ctrlKey && !e.shiftKey) {
      switch (e.key.toLowerCase()) {
        case "delete":
          e.preventDefault();
          this.delete();
          break;
        case "s":
          e.preventDefault();
          this.save(null, true);
          break;
        case "p":
          e.preventDefault();
          this.pin();
          break;
        case "r":
          e.preventDefault();
          this.showReminderDialog();
          break;
        case "t":
          e.preventDefault();
          this.showTagsDialog();
          break;
        case "b":
          e.preventDefault();
          this.setCommand("bold", null);
          break;
        case "i":
          e.preventDefault();
          this.setCommand("italic", null);
          break;
        case "u":
          e.preventDefault();
          this.setCommand("underline", null);
          break;
        case "-":
          e.preventDefault();
          this.setCommand("strikeThrough", null);
          break;
        case "l":
          e.preventDefault();
          this.setCommand("insertHorizontalRule", null);
          break;
        case "1":
          e.preventDefault();
          this.setCommand("insertOrderedList", null);
          break;
        case ".":
          this.setCommand("insertUnorderedList", null);
          e.preventDefault();
          break;
      }
    }

    if (e.ctrlKey && e.shiftKey) {
      switch (e.key.toLowerCase()) {
        case "r":
          e.preventDefault();
          this.setCommand("justifyRight", null);
          break;
        case "c":
          e.preventDefault();
          this.setCommand("justifyCenter", null);
          break;
        case "l":
          e.preventDefault();
          this.setCommand("justifyLeft", null);
          break;
        case "<":
          e.preventDefault();
          this.setCommand("outdent", null);
          break;
        case ">":
          this.setCommand("indent", null);
          e.preventDefault();
          break;
      }
    }
  }

  onKeyDownTitle = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("content").focus();
    }
  };

  changeColor = (colorToChange) => {
    var tempBackgroundClass, tempHeaderBackgroundClass;
    tempBackgroundClass = "background_" + colorToChange;
    tempHeaderBackgroundClass = "background_" + colorToChange;

    this.setState(
      (prevState) => {
        return {
          note: {
            ...prevState.note,
            color: colorToChange,
          },
          background_color_class: tempBackgroundClass,
          header_background_color: tempHeaderBackgroundClass,
          change: true,
        };
      },
      () => this.getActiveCommands
    );
  };

  showReminderDialog() {
    this.setState({
      showReminderDialog: true,
    });
  }

  onCloseReminderDialog() {
    this.setState({
      showReminderDialog: false,
    });
  }

  setReminderState(reminderDate) {
    let reminder_title = this.state.reminder_title;
    if (reminderDate !== null) {
      const sReminderDate = reminderDate.split("_");
      reminder_title =
        sReminderDate[0] +
        ":" +
        sReminderDate[1] +
        " " +
        sReminderDate[2] +
        "/" +
        sReminderDate[3] +
        "/" +
        sReminderDate[4];
    }

    this.setState({
      reminder_title: reminder_title,
    });
  }

  onCloseDialogSetReminder(reminderDate) {
    let reminder_title = "";

    if (reminderDate !== null) {
      const sReminderDate = reminderDate.split("_");
      reminder_title =
        sReminderDate[0] +
        ":" +
        sReminderDate[1] +
        " " +
        sReminderDate[2] +
        "/" +
        sReminderDate[3] +
        "/" +
        sReminderDate[4];
    } else {
      reminder_title = "Not set";
    }

    this.setState((prevState) => {
      return {
        note: {
          ...prevState.note,
          reminderDate: reminderDate,
        },
        showReminderDialog: false,
        reminder_title: reminder_title,
        change: true,
      };
    });
  }

  onViewTypeChange(view) {
    this.setState({
      view: view,
    });
  }

  backgroundSync() {
    const note = this.state.note;

    if (this.state.change) {
      this.setState({
        change: false,
      });

      if (note.id === undefined) {
        TextNoteService.create(this.state.parentId, note)
          .then((response) => {
            this.setState((prevState) => {
              return {
                note: {
                  ...prevState.note,
                  id: response.data.id,
                },
              };
            });
          })
          .catch((e) => {
            methods.processError(e);
          });
      } else {
        TextNoteService.update(note)
          .then(() => {})
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      TextNoteService.get(note.id)
        .then((response) => {
          if (response.data === "") {
            this.setState({
              closeDialog: true,
            });
          } else {
            const note = response.data;

            this.setReminderState(note.reminderDate);

            CookieHelper.extendValidity();

            this.changeColor(note.color);
            this.setPinState(note.pinned);

            const data = Methods.getURLsAndEmails(note.content);
            const tempEmails = data.emails;
            const tempUrls = data.urls;

            this.setState({
              note: note,
              urls: tempUrls,
              emails: tempEmails,
              change: false,
            });
          }
        })
        .catch((e) => {
          methods.processError(e);
        });
    }
  }

  initBackgroundSync() {
    const intervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);
    this.setState({
      syncIntervalId: intervalId,
    });
  }

  showTagsDialog() {
    this.setState({
      canShowTagsDialog: true,
    });
  }

  onSelectedTag(tag) {
    this.setState((prevState) => {
      return {
        note: {
          ...prevState.note,
          tag: tag,
        },
        canShowTagsDialog: false,
        change: true,
      };
    });
  }

  onCloseTagsDialog() {
    this.setState({
      canShowTagsDialog: false,
    });
  }

  render() {
    // DATA
    let note = this.state.note;
    let urls = this.state.urls;
    let emails = this.state.emails;
    let tagGroupId = this.state.tagGroupId;
    let reminderTitle = this.state.reminder_title;
    let parentFolderId = this.state.parentId;
    let noteContent = note.content ? note.content : "";

    // UI
    let loadingNote = this.state.loadingNote;
    let view = this.state.view;
    let canShowTagsDialog = this.state.canShowTagsDialog;
    let showDeleteDialog = this.state.showDeleteDialog;
    let showReminderDialog = this.state.showReminderDialog;
    let closeDialog = this.state.closeDialog;

    return (
      <div>
        {loadingNote ? <div id="loader" /> : null}

        <header className={this.state.background_color_class}>
          <button
            title="Close"
            className="action_button action_close action_button_left"
            onClick={(event) => this.save(event, false)}
          />

          <nav>
            <ul>
              <li>
                <button
                  title="Delete"
                  className="action_button action_delete"
                  onClick={this.delete}
                />
              </li>
              <li>
                <button
                  title={this.state.pin_title}
                  className={this.state.pin_class}
                  onClick={this.pin}
                />
              </li>
              {(urls && urls.length > 0) || (emails && emails.length > 0) ? (
                <li>
                  {view === "note" ? (
                    <button
                      title="Show urls and emails"
                      className="action_button action_show_links"
                      onClick={() => this.onViewTypeChange("links")}
                    />
                  ) : (
                    <button
                      title="Edit note"
                      className="action_button action_edit_note"
                      onClick={() => this.onViewTypeChange("note")}
                    />
                  )}
                </li>
              ) : null}
            </ul>
          </nav>
        </header>

        <div className="note_container">
          <input
            maxLength={50}
            placeholder="Title"
            className="edit_title"
            type="text"
            value={note.title}
            onKeyDown={this.onKeyDownTitle}
            onChange={this.onChangeTitle}
          />

          {view === "note" ? (
            <div className="rich_text_editor_wrapper">
              <div className="rich_text_editor_toolbar">
                <div className={this.state.bullet_list_class}>
                  <button
                    title="Bullet list"
                    id="rte_bullet_list"
                    className="rte_button rte_bullet_list"
                    onClick={() => {
                      this.setCommand("insertUnorderedList", null);
                    }}
                  />
                </div>
                <div className={this.state.numbered_list_class}>
                  <button
                    title="Numbered list"
                    id="rte_numbered_list"
                    className="rte_button rte_numbered_list"
                    onClick={() => {
                      this.setCommand("insertOrderedList", null);
                    }}
                  />
                </div>
                <div className={this.state.indent_class}>
                  <button
                    title="Indent"
                    id="rte_indent"
                    className="rte_button rte_indent"
                    onClick={() => {
                      this.setCommand("indent", null);
                    }}
                  />
                </div>
                <div className={this.state.outdent_class}>
                  <button
                    title="Outdent"
                    id="rte_outdent"
                    className="rte_button rte_outdent"
                    onClick={() => {
                      this.setCommand("outdent", null);
                    }}
                  />
                </div>
                <div className={this.state.bold_class}>
                  <button
                    title="Bold"
                    id="rte_bold"
                    className="rte_button rte_bold"
                    onClick={() => {
                      this.setCommand("bold", null);
                    }}
                  />
                </div>
                <div className={this.state.italic_class}>
                  <button
                    title="Italic"
                    id="rte_italic"
                    className="rte_button rte_italic"
                    onClick={() => {
                      this.setCommand("italic", null);
                    }}
                  />
                </div>
                <div className={this.state.underline_class}>
                  <button
                    title="Underline"
                    id="rte_underline"
                    className="rte_button rte_underline"
                    onClick={() => {
                      this.setCommand("underline", null);
                    }}
                  />
                </div>
                <div className={this.state.strike_throught_class}>
                  <button
                    title="Strike throught"
                    id="rte_strike_throught"
                    className="rte_button rte_strike_throught"
                    onClick={() => {
                      this.setCommand("strikeThrough", null);
                    }}
                  />
                </div>
                <div className="rte_font_size_div">
                  <select
                    title="Font size"
                    id="rte_font_size"
                    defaultValue="3"
                    className="rte_font_size"
                    onChange={(event) =>
                      this.setCommand("fontSize", event.target.value)
                    }
                  >
                    <option value="1">10</option>
                    <option value="2">12</option>
                    <option value="3">16</option>
                    <option value="4">24</option>
                    <option value="5">32</option>
                    <option value="6">44</option>
                    <option value="7">60</option>
                  </select>
                </div>
                <div className={this.state.align_left_class}>
                  <button
                    title="Align left"
                    id="rte_align_left"
                    className="rte_button rte_align_left"
                    onClick={() => {
                      this.setCommand("justifyLeft", null);
                    }}
                  />
                </div>
                <div className={this.state.align_center_class}>
                  <button
                    title="Align center"
                    id="rte_align_center"
                    className="rte_button rte_align_center"
                    onClick={() => {
                      this.setCommand("justifyCenter", null);
                    }}
                  />
                </div>
                <div className={this.state.align_right_class}>
                  <button
                    title="Align right"
                    id="rte_align_right"
                    className="rte_button rte_align_right"
                    onClick={() => {
                      this.setCommand("justifyRight", null);
                    }}
                  />
                </div>
                <div className="rte_button_div">
                  <button
                    title="Horizontal line"
                    id="rte_horizontal_line"
                    className="rte_button rte_horizontal_line"
                    onClick={() => {
                      this.setCommand("insertHorizontalRule", null);
                    }}
                  />
                </div>
              </div>
              <ContentEditable
                id="rte_editor"
                html={noteContent}
                className="rich_text_editor_editor"
                onChange={this.onChangeDescription}
                placeholder="Write note"
              />
            </div>
          ) : null}

          {view === "links" ? (
            <div className="links_wrapper">
              <div className="outer_container_scroll_links">
                <div className="inner_container_scroll">
                  {urls && urls.length > 0 ? (
                    <div>
                      <label className="list_title">URLs in note</label>

                      {urls.map((url) => (
                        <a target="_blank" rel="noopener noreferrer" href={url}>
                          <div className="row_link">
                            <label className="row_title">{url}</label>
                          </div>
                        </a>
                      ))}
                      <br />
                    </div>
                  ) : null}

                  {emails && emails.length > 0 ? (
                    <div>
                      <label className="list_title">
                        Email addresses in note
                      </label>

                      {emails.map((email) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"mailto:" + email}
                        >
                          <div className="row_link">
                            <label className="row_title">{email}</label>
                          </div>
                        </a>
                      ))}
                      <br />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {view === "note" ? (
            <div className="bottom_properties">
              {reminderTitle === "Not set" ? (
                <div className="div_reminder">
                  <img
                    title="Set reminder"
                    src={require("../../icons/reminder-off.svg").default}
                    height="30"
                    width="30"
                    alt="SetReminder"
                    style={{ margin: "10px" }}
                    onClick={this.showReminderDialog}
                  />
                  <label title="Set reminder" onClick={this.showReminderDialog}>
                    Set reminder
                  </label>
                </div>
              ) : (
                <div className="div_reminder">
                  <img
                    title="Set reminder"
                    src={require("../../icons/reminder-on.svg").default}
                    height="30"
                    width="30"
                    alt="Select tag group"
                    style={{ margin: "10px" }}
                    onClick={this.showReminderDialog}
                  />
                  <label title="Set reminder" onClick={this.showReminderDialog}>
                    {reminderTitle}
                  </label>
                </div>
              )}

              {tagGroupId !== null ? (
                <div className="div_tag">
                  {note.tag ? (
                    <div>
                      <img
                        title="Select tag"
                        src={require("../../icons/tag.svg").default}
                        height="30"
                        width="30"
                        alt="Select tag"
                        style={{ margin: "10px" }}
                        onClick={this.showTagsDialog}
                      />
                      <label title="Select tag" onClick={this.showTagsDialog}>
                        {note.tag.title}
                      </label>
                    </div>
                  ) : (
                    <div>
                      <img
                        title="Select tag"
                        src={require("../../icons/tag_outline.svg").default}
                        height="30"
                        width="30"
                        alt="Select tag"
                        style={{ margin: "10px" }}
                        onClick={this.showTagsDialog}
                      />
                      <label title="Select tag" onClick={this.showTagsDialog}>
                        Select tag
                      </label>
                    </div>
                  )}
                </div>
              ) : (
                <div className="div_tag" />
              )}

              <ColorPickerDiv
                isNote={true}
                selectedColor={note.color}
                changeColor={this.changeColor}
              />
            </div>
          ) : null}
        </div>

        {canShowTagsDialog ? (
          <ChooseTagDialogComponent
            tagGroupId={tagGroupId}
            onClose={this.onCloseTagsDialog}
            onClick={this.onSelectedTag}
          />
        ) : null}

        {showDeleteDialog ? (
          <DeleteDialog onDeleteConfirm={this.onDeleteConfirm} type="note" />
        ) : null}

        {showReminderDialog ? (
          <ReminderDialog
            reminderDate={note.reminderDate}
            onCloseDialogSetReminder={this.onCloseDialogSetReminder}
            onCloseDialog={this.onCloseReminderDialog}
          />
        ) : null}

        {closeDialog ? (
          <Navigate
            to={parentFolderId !== null ? "/" + parentFolderId : "/"}
            replace={true}
          />
        ) : null}
      </div>
    );
  }
}
