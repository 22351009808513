class Methods {
  processError(error) {
    if (error.response.status === 401) {
      window.location.href =
        "https://authentication.davidsimak.dev/?appName=notes";
      return;
    }

    if (error.response.status === 403) {
      window.location.href = "https://www.davidsimak.dev/access-denied";
      return;
    }

    if (error.response.status === 404) {
      window.location.href = "https://www.davidsimak.dev/not-found";
      return;
    }

    window.location.href = "https://www.davidsimak.dev/unavailable-service";
  }
  replaceAt(original, index, replacement) {
    return (
      original.substring(0, index) +
      replacement +
      original.substring(index + replacement.length)
    );
  }

  getURLsAndEmails(text) {
    const tempEmails = [];
    const tempURLS = [];

    const reUrl =
      /(\b(http?|https?|ftp|sftp|file|gopher|telnet):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    const reEmail = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

    text.replace(reUrl, function (url) {
      if (tempURLS.indexOf(url) === -1) {
        tempURLS.push(url);
      }
      return url;
    });

    text.replace(reEmail, function (email) {
      if (tempEmails.indexOf(email) === -1) {
        tempEmails.push(email);
      }
      return email;
    });

    return {
      urls: tempURLS,
      emails: tempEmails,
    };
  }
}

export default new Methods();
