import axios from "axios";

// const baseURL = "https://api.notes.davidsimak.dev";
const baseURL = "http://localhost:8080/reporting/react-visit";

export default axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});
