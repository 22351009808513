import React, { Component } from "react";
import EditTagsDialog from "./edit-tags-dialog.component";
import TagService from "../../services/tags.service";
import methods from "../../methods";

export default class ListTagGroupDialogComponent extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.clickTagGroup = this.clickTagGroup.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onCloseEditDialog = this.onCloseEditDialog.bind(this);
    this.addNewTagGroup = this.addNewTagGroup.bind(this);
    this.getTagGroups = this.getTagGroups.bind(this);

    this.state = {
      tagGroups: [],
      loadingTagGroups: false,
      selector: false,
      showEditTagGroupDialog: false,
      tagGroupId: null,
    };
  }

  addNewTagGroup() {
    this.setState({
      showEditTagGroupDialog: true,
      tagGroupId: null,
    });
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey, false);
    this.setState({
      selector: this.props.selector,
    });

    this.getTagGroups();
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      this.close();
    }
  }

  clickTagGroup(tag) {
    if (this.state.selector) {
      this.props.onClick(tag);
    } else {
      this.setState({
        showEditTagGroupDialog: true,
        tagGroupId: tag.id,
      });
    }
  }

  onCloseEditDialog() {
    this.setState({
      showEditTagGroupDialog: false,
      tagGroupId: null,
    });

    this.getTagGroups();
  }

  getTagGroups() {
    this.setState({
      loadingTagGroups: true,
    });

    TagService.getAll()
      .then((response) => {
        this.setState({
          loadingTagGroups: false,
          tagGroups: response.data,
        });
      })
      .catch((error) => {
        methods.processError(error);
      });
  }

  close() {
    this.props.onClose();
  }

  render() {
    return (
      <div className="dialog_wrapper">
        {this.state.loadingTagGroups ? <div id="loader2" /> : null}

        <div className="dialog item_dialog tag_dialog">
          <div className="dialog_header background_default">
            <div className="dialog_title_folder">
              <button
                title="Close"
                className="action_button action_close"
                onClick={this.close}
              />
              <input
                maxLength={50}
                placeholder="Title"
                disabled
                className="edit_title_folder"
                type="text"
                value={
                  this.state.selector ? "Select group of tags" : "Group of tags"
                }
              />
            </div>

            {this.state.selector ? null : (
              <nav>
                <ul>
                  <li>
                    <button
                      title="Add"
                      className="action_button action_add"
                      onClick={this.addNewTagGroup}
                    />
                  </li>
                </ul>
              </nav>
            )}
          </div>

          <div className="dialog_container tag_dialog_container">
            <div className="outer_container_scroll tag_scroll">
              <div className="inner_container_scroll">
                {this.state.selector ? (
                  <div
                    className="tag_group_row"
                    onClick={() => this.clickTagGroup(null)}
                  >
                    <label className="tag_group_title">None</label>
                  </div>
                ) : null}

                {this.state.tagGroups &&
                  this.state.tagGroups.map((tagGroup) => (
                    <div
                      className="tag_group_row"
                      key={tagGroup.id}
                      onClick={() => this.clickTagGroup(tagGroup)}
                    >
                      <label className="tag_group_title">
                        {tagGroup.title}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {this.state.showEditTagGroupDialog ? (
          <EditTagsDialog
            id={this.state.tagGroupId}
            onClose={this.onCloseEditDialog}
          />
        ) : null}
      </div>
    );
  }
}
