import http from "../http-common";

class ChecklistNoteService {
  get(id) {
    return http.get(`/checklistNotes/${id}`);
  }

  create(parentId, data) {
    if (parentId === null) {
      return http.post("/checklistNotes", data);
    } else {
      return http.post(`/checklistNotes?parentId=${parentId}`, data);
    }
  }

  update(data) {
    return http.put(`/checklistNotes`, data);
  }

  updateDestination(data) {
    return http.put("checklistNotes/destination", data);
  }

  delete(id) {
    return http.delete(`/checklistNotes/${id}`);
  }
}

export default new ChecklistNoteService();
