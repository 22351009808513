import Cookies from "js-cookie";

const cookieName = "authentication";

class CookieHelper {
  extendValidity() {
    const cookieValue = this.get();
    this.remove(cookieName);
    Cookies.set(cookieName, cookieValue, {
      expires: 14,
      domain: "davidsimak.dev",
    });
  }

  remove() {
    Cookies.remove(cookieName, { domain: "davidsimak.dev" });
  }

  get() {
    const cookieValue = Cookies.get(cookieName, { domain: "davidsimak.dev" });

    if (cookieValue === undefined) {
      window.location.href =
        "https://authentication.davidsimak.dev/?redirect=notes";
      return;
    }

    return cookieValue === undefined ? "" : cookieValue;
  }
}

export default new CookieHelper();
