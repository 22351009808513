import http from "../http-common";

class ItemsService {
  getAll() {
    return http.get("/items");
  }

  getByFolder(parentId) {
    return http.get(`/items?parentId=${parentId}`);
  }

  getReminders(date) {
    return http.get(`/items/reminders?reminderDate=` + date);
  }
}

export default new ItemsService();
