import React, { Component } from "react";
import ChecklistService from "../../services/checklist-note.service";
import CookieHelper from "../../cookie-helper";
import DeleteDialog from "../dialog/delete-dialog.component";
import ReminderDialog from "../dialog/reminder-dialog.componet";
import ColorPickerDiv from "../general/div-color-picker.component";
import ChooseTagDialogComponent from "../tag/choose-tag-dialog.component";
import { Navigate } from "react-router-dom";
import methods from "../../methods";
import reportVisitService from "../../reporing-visit/report-visit.service";

export default class ChecklistNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.save = this.save.bind(this);
    this.addNewChecklist = this.addNewChecklist.bind(this);
    this.removeChecklist = this.removeChecklist.bind(this);
    this.onChangeNewChecklistTitle = this.onChangeNewChecklistTitle.bind(this);
    this.onChangeChecklistTitle = this.onChangeChecklistTitle.bind(this);
    this.delete = this.delete.bind(this);
    this.getChecklistNote = this.getChecklistNote.bind(this);
    this.onKeyDownChecklist = this.onKeyDownChecklist.bind(this);
    this.onKeyDownTitle = this.onKeyDownTitle.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.pin = this.pin.bind(this);
    this.setPinState = this.setPinState.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.showReminderDialogChange = this.showReminderDialogChange.bind(this);
    this.onCloseReminderDialog = this.onCloseReminderDialog.bind(this);
    this.onCloseDialogSetReminder = this.onCloseDialogSetReminder.bind(this);
    this.setReminderState = this.setReminderState.bind(this);
    this.initBackgroundSync = this.initBackgroundSync.bind(this);
    this.backgroundSync = this.backgroundSync.bind(this);
    this.showTagsDialog = this.showTagsDialog.bind(this);
    this.onSelectedTag = this.onSelectedTag.bind(this);
    this.onCloseTagsDialog = this.onCloseTagsDialog.bind(this);

    this.state = {
      checklistNote: {},
      change: false,
      tempChecklistTitle: "",
      loadingNote: false,
      reminder_title: "Not set",
      pin_class: "action_button action_unpinned",
      pin_title: "",
      showDeleteDialog: false,
      showReminderDialog: false,
      syncIntervalId: "",
      parentId: null,
      tagGroupId: null,
      canShowTagsDialog: false,
      closeDialog: false,
    };
  }

  componentDidMount() {
    let parentId = null;
    const pathname = window.location.pathname;
    if (pathname.includes("newChecklist")) {
      let tagGroupId = null;

      if (!pathname.startsWith("/newChecklist")) {
        parentId = pathname.split("/")[1];
      }

      if (window.location.search !== "") {
        tagGroupId = window.location.search.replace("?tagGroupId=", "");
      }

      const tempChecklistNote = {
        title: "",
        checklist: [],
        color: "default",
        pinned: false,
        reminderDate: null,
      };

      this.setState({
        checklistNote: tempChecklistNote,
        parentId: parentId,
        change: true,
        tagGroupId: tagGroupId,
      });

      document.title = "New checklist | Notes";
      this.initBackgroundSync();
    } else {
      let id = "";
      const pathnameSplit = pathname.split("/");

      if (pathname.startsWith("/checklist")) {
        id = pathnameSplit[2];
      } else {
        id = pathnameSplit[3];
        parentId = pathnameSplit[1];
      }

      this.getChecklistNote(id);

      this.setState({
        parentId: parentId,
      });
    }
    document.addEventListener("keydown", this.handleEscapeKey, false);
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
    document.removeEventListener("keydown", this.handleEscapeKey, false);
  }

  getChecklistNote(id) {
    this.setState({
      loadingNote: true,
    });
    ChecklistService.get(id)
      .then((response) => {
        this.initBackgroundSync();
        CookieHelper.extendValidity();

        const checklistNote = response.data;

        this.setReminderState(checklistNote.reminderDate);
        this.changeColor(checklistNote.color);
        this.setPinState(checklistNote.pinned);

        this.setState({
          checklistNote: checklistNote,
          tagGroupId: checklistNote.tagGroupId,
          change: false,
          loadingNote: false,
        });

        document.title = checklistNote.title + " | Notes";
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  addNewChecklist() {
    const item = {
      title: this.state.tempChecklistTitle,
      check: false,
    };

    this.state.checklistNote.checklist.push(item);

    const tempChecklistUnchecked = [];
    const tempChecklistChecked = [];

    let tempChecklist = this.state.checklistNote.checklist;
    tempChecklist.forEach((item) => {
      if (item.check === true) {
        tempChecklistChecked.push(item);
      } else {
        tempChecklistUnchecked.push(item);
      }
    });

    tempChecklist = [];
    tempChecklistUnchecked.map((item) => {
      tempChecklist.push(item);
    });
    tempChecklistChecked.map((item) => {
      tempChecklist.push(item);
    });

    this.setState((prevState) => {
      return {
        tempChecklistTitle: "",
        change: true,
        checklistNote: {
          ...prevState.checklistNote,
          checklist: tempChecklist,
          checklistStatus:
            tempChecklistChecked.length + "/" + tempChecklist.length,
        },
      };
    });
  }

  removeChecklist(deleteIndex) {
    const tempChecklist = [];
    const tempChecklistChecked = [];

    this.state.checklistNote.checklist.forEach((item, index) => {
      if (deleteIndex !== index) {
        tempChecklist.push(item);
      }
    });

    tempChecklist.forEach((item) => {
      if (item.check === true) {
        tempChecklistChecked.push(item);
      }
    });

    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          checklist: tempChecklist,
          checklistStatus:
            tempChecklistChecked.length + "/" + tempChecklist.length,
        },
        change: true,
      };
    });
  }

  onChangeChecklistTitle(title, changeIndex) {
    const tempChecklist = [];
    this.state.checklistNote.checklist.forEach((checklist, index) => {
      if (changeIndex !== index) {
        tempChecklist.push(checklist);
      } else {
        checklist.title = title;
        tempChecklist.push(checklist);
      }
    });

    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          checklist: tempChecklist,
        },
        change: true,
      };
    });
  }

  onChangeChecklistCheck(check, changeIndex) {
    let tempChecklist = [];
    this.state.checklistNote.checklist.forEach((item, index) => {
      if (changeIndex !== index) {
        tempChecklist.push(item);
      } else {
        item.check = check;
        tempChecklist.push(item);
      }
    });

    const tempChecklistUnchecked = [];
    const tempChecklistChecked = [];

    tempChecklist.forEach((item) => {
      if (item.check === true) {
        tempChecklistChecked.push(item);
      } else {
        tempChecklistUnchecked.push(item);
      }
    });

    tempChecklist = [];
    tempChecklistUnchecked.map((item) => {
      tempChecklist.push(item);
    });
    tempChecklistChecked.map((item) => {
      tempChecklist.push(item);
    });

    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          checklist: tempChecklist,
          checklistStatus:
            tempChecklistChecked.length + "/" + tempChecklist.length,
        },
        change: true,
      };
    });
  }

  onChangeNewChecklistTitle(e) {
    this.setState({
      tempChecklistTitle: e.target.value,
    });
  }

  onChangeTitle(e) {
    const newTitle = e.target.value;
    document.title = newTitle + " | Notes";
    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          title: newTitle,
        },
        change: true,
      };
    });
  }

  pin() {
    const newPinnedState = !this.state.checklistNote.pinned;
    this.setPinState(newPinnedState);
    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          pinned: newPinnedState,
        },
        change: true,
      };
    });
  }

  setPinState(pinned) {
    const pinClass = this.state.pin_class;
    let newPinClass = pinClass;
    let pinTitle = "Pin note";

    if (pinned && pinClass.includes("unpinned")) {
      newPinClass = pinClass.replace("unpinned", "pinned");
      pinTitle = "Unpin note";
    } else if (!pinned && pinClass.includes("_pinned")) {
      newPinClass = pinClass.replace("_pinned", "_unpinned");
      pinTitle = "Pin note";
    }

    this.setState({
      pin_class: newPinClass,
      pin_title: pinTitle,
    });
  }

  save(event, isShortcut) {
    if (isShortcut || event.target === event.currentTarget) {
      const parentId = this.state.parentId;
      const checklistNote = this.state.checklistNote;

      if (
        checklistNote.title.length > 0 ||
        checklistNote.checklist.length > 0
      ) {
        this.setState({
          loadingNote: true,
        });

        if (checklistNote.id === undefined) {
          ChecklistService.create(parentId, checklistNote)
            .then(() => {
              this.setState({
                closeDialog: true,
              });
            })
            .catch((e) => {
              methods.processError(e);
            });
        } else {
          ChecklistService.update(checklistNote)
            .then(() => {
              this.setState({
                closeDialog: true,
              });
            })
            .catch((e) => {
              methods.processError(e);
            });
        }
      } else {
        this.setState({
          closeDialog: true,
        });
      }
    }
  }

  delete() {
    this.setState({
      showDeleteDialog: true,
    });
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      const parentId = this.state.parentId;
      const id = this.state.checklistNote.id;
      if (id === undefined) {
        this.setState({
          closeDialog: true,
        });
      } else {
        ChecklistService.delete(id)
          .then(() => {
            this.setState({
              closeDialog: true,
            });
          })
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      this.setState({
        showDeleteDialog: false,
      });
    }
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      if (this.state.showDeleteDialog) {
        this.setState({
          showDeleteDialog: false,
        });
      } else {
        this.save(null, true);
      }
    } else if (e.key.toLowerCase() === "delete" && e.ctrlKey) {
      e.preventDefault();
      this.delete();
    } else if (e.key.toLowerCase() === "s" && e.ctrlKey) {
      e.preventDefault();
      this.save(null, true);
    } else if (e.key.toLowerCase() === "p" && e.ctrlKey) {
      e.preventDefault();
      this.pin();
    } else if (e.key.toLowerCase() === "r" && e.ctrlKey) {
      e.preventDefault();
      this.showReminderDialog();
    } else if (e.key.toLowerCase() === "t" && e.ctrlKey) {
      e.preventDefault();
      this.showTagsDialog();
    }
  }

  onKeyDownChecklist = (e) => {
    if (e.key === "Enter") {
      this.addNewChecklist();
    }
  };

  onKeyDownTitle = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.getElementById("checklist_add_title").focus();
    }
  };

  changeColor = (colorToChange) => {
    var tempBackgroundClass, tempHeaderBackgroundClass;
    tempBackgroundClass = "background_" + colorToChange;
    tempHeaderBackgroundClass = "background_" + colorToChange;

    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          color: colorToChange,
        },
        background_color_class: tempBackgroundClass,
        header_background_color: tempHeaderBackgroundClass,
        change: true,
      };
    });
  };

  showReminderDialogChange() {
    this.setState({
      showReminderDialog: true,
    });
  }

  onCloseReminderDialog() {
    this.setState({
      showReminderDialog: false,
    });
  }

  setReminderState(reminderDate) {
    let reminder_title = this.state.reminder_title;
    if (reminderDate !== null) {
      const sReminderDate = reminderDate.split("_");
      reminder_title =
        sReminderDate[0] +
        ":" +
        sReminderDate[1] +
        " " +
        sReminderDate[2] +
        "/" +
        sReminderDate[3] +
        "/" +
        sReminderDate[4];
    }

    this.setState({
      reminder_title: reminder_title,
    });
  }

  onCloseDialogSetReminder(reminderDate) {
    let reminder_title = "";

    if (reminderDate !== null) {
      const sReminderDate = reminderDate.split("_");
      reminder_title =
        sReminderDate[0] +
        ":" +
        sReminderDate[1] +
        " " +
        sReminderDate[2] +
        "/" +
        sReminderDate[3] +
        "/" +
        sReminderDate[4];
    } else {
      reminder_title = "Not set";
    }

    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          reminderDate: reminderDate,
        },
        showReminderDialog: false,
        reminder_title: reminder_title,
        change: true,
      };
    });
  }

  showTagsDialog() {
    this.setState({
      canShowTagsDialog: true,
    });
  }

  onSelectedTag(tag) {
    this.setState((prevState) => {
      return {
        checklistNote: {
          ...prevState.checklistNote,
          tag: tag,
        },
        canShowTagsDialog: false,
        change: true,
      };
    });
  }

  onCloseTagsDialog() {
    this.setState({
      canShowTagsDialog: false,
    });
  }

  backgroundSync() {
    const checklistNote = this.state.checklistNote;
    const parentId = this.state.parentId;

    if (this.state.change) {
      this.setState({
        change: false,
      });

      if (checklistNote.id === undefined) {
        ChecklistService.create(parentId, checklistNote)
          .then((response) => {
            this.setState((prevState) => {
              return {
                checklistNote: {
                  ...prevState.checklistNote,
                  id: response.data.id,
                },
              };
            });
          })
          .catch((e) => {
            methods.processError(e);
          });
      } else {
        ChecklistService.update(checklistNote)
          .then(() => {})
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      ChecklistService.get(checklistNote.id)
        .then((response) => {
          if (response.data === "") {
            this.setState({
              closeDialog: true,
            });
          } else {
            CookieHelper.extendValidity();

            const note = response.data;

            this.setReminderState(note.reminderDate);
            this.changeColor(note.color);
            this.setPinState(note.pinned);

            this.setState({
              checklistNote: note,
              change: false,
              loadingNote: false,
            });
          }
        })
        .catch((e) => {
          methods.processError(e);
        });
    }
  }

  initBackgroundSync() {
    const intervalId = setInterval(() => {
      this.backgroundSync();
    }, 60000);
    this.setState({
      syncIntervalId: intervalId,
    });
  }

  render() {
    // DATA
    const checklistNote = this.state.checklistNote;
    const tagGroupId = this.state.tagGroupId;
    const reminderTitle = this.state.reminder_title;
    let parentFolderId = this.state.parentId;

    // UI
    const loading = this.state.loadingNote;
    const showDeleteDialog = this.state.showDeleteDialog;
    const canShowTagsDialog = this.state.canShowTagsDialog;
    const showReminderDialog = this.state.showReminderDialog;
    const closeDialog = this.state.closeDialog;

    return (
      <div>
        {loading ? <div id="loader" /> : null}

        <header className={this.state.background_color_class}>
          <button
            title="Close"
            className="action_button action_close action_button_left"
            onClick={(event) => this.save(event, false)}
          />

          <nav>
            <ul>
              <li>
                <button
                  title="Delete"
                  className="action_button action_delete"
                  onClick={this.delete}
                />
              </li>
              <li>
                <button
                  title={this.state.pin_title}
                  className={this.state.pin_class}
                  onClick={this.pin}
                />
              </li>
            </ul>
          </nav>
        </header>

        <div className="note_container">
          <input
            maxLength={50}
            placeholder="Title"
            className="edit_title"
            type="text"
            value={checklistNote.title}
            onKeyDown={this.onKeyDownTitle}
            onChange={this.onChangeTitle}
          />

          <div className="checklist_wrapper">
            <div className="div_add_item_to_checklist">
              <button
                title="Add new item to checklist"
                className="button_add_checklist"
                onClick={this.addNewChecklist}
              />

              <input
                type="text"
                autoComplete="off"
                placeholder="New item"
                value={this.state.tempChecklistTitle}
                className="checklist_add_title"
                onKeyDown={this.onKeyDownChecklist}
                onChange={this.onChangeNewChecklistTitle}
              />
            </div>
            <div className="outer_container_scroll_checklist">
              <div className="inner_container_scroll">
                {checklistNote.checklist &&
                  checklistNote.checklist.map((item, index) => (
                    <div className="checklist_row">
                      {item.check ? (
                        <div className="checklist_row_inner">
                          <button
                            className="checklist_row_checked"
                            onClick={() =>
                              this.onChangeChecklistCheck(false, index)
                            }
                          />
                          <input
                            type="text"
                            className="checklist_title line_through"
                            value={item.title}
                            onChange={(e) =>
                              this.onChangeChecklistTitle(e.target.value, index)
                            }
                          />
                          <button
                            title="Delete item from checklist"
                            className="button_delete_checklist"
                            onClick={() => this.removeChecklist(index)}
                          />
                        </div>
                      ) : (
                        <div className="checklist_row_inner">
                          <button
                            className="checklist_row_unchecked"
                            onClick={() =>
                              this.onChangeChecklistCheck(true, index)
                            }
                          />
                          <input
                            type="text"
                            className="checklist_title"
                            value={item.title}
                            onChange={(e) =>
                              this.onChangeChecklistTitle(e.target.value, index)
                            }
                          />
                          <button
                            title="Delete item from checklist"
                            className="button_delete_checklist"
                            onClick={() => this.removeChecklist(index)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="bottom_properties">
            {reminderTitle === "Not set" ? (
              <div className="div_reminder">
                <img
                  title="Set reminder"
                  src={require("../../icons/reminder-off.svg").default}
                  height="30"
                  width="30"
                  alt="SetReminder"
                  style={{ margin: "10px" }}
                  onClick={this.showReminderDialog}
                />
                <label title="Set reminder" onClick={this.showReminderDialog}>
                  Set reminder
                </label>
              </div>
            ) : (
              <div className="div_reminder">
                <img
                  title="Set reminder"
                  src={require("../../icons/reminder-on.svg").default}
                  height="30"
                  width="30"
                  alt="Select tag group"
                  style={{ margin: "10px" }}
                  onClick={this.showReminderDialog}
                />
                <label title="Set reminder" onClick={this.showReminderDialog}>
                  {reminderTitle}
                </label>
              </div>
            )}

            {tagGroupId !== null ? (
              <div className="div_tag">
                {checklistNote.tag ? (
                  <div>
                    <img
                      title="Select tag"
                      src={require("../../icons/tag.svg").default}
                      height="30"
                      width="30"
                      alt="Select tag"
                      style={{ margin: "10px" }}
                      onClick={this.showTagsDialog}
                    />
                    <label title="Select tag" onClick={this.showTagsDialog}>
                      {checklistNote.tag.title}
                    </label>
                  </div>
                ) : (
                  <div>
                    <img
                      title="Select tag"
                      src={require("../../icons/tag_outline.svg").default}
                      height="30"
                      width="30"
                      alt="Select tag"
                      style={{ margin: "10px" }}
                      onClick={this.showTagsDialog}
                    />
                    <label title="Select tag" onClick={this.showTagsDialog}>
                      Select tag
                    </label>
                  </div>
                )}
              </div>
            ) : (
              <div className="div_tag" />
            )}

            <ColorPickerDiv
              isNote={true}
              selectedColor={checklistNote.color}
              changeColor={this.changeColor}
            />
          </div>
        </div>

        {canShowTagsDialog ? (
          <ChooseTagDialogComponent
            tagGroupId={tagGroupId}
            onClose={this.onCloseTagsDialog}
            onClick={this.onSelectedTag}
          />
        ) : null}

        {showDeleteDialog ? (
          <DeleteDialog
            onDeleteConfirm={this.onDeleteConfirm}
            type="checklist"
          />
        ) : null}

        {showReminderDialog ? (
          <ReminderDialog
            reminderDate={checklistNote.reminderDate}
            onCloseDialogSetReminder={this.onCloseDialogSetReminder}
            onCloseDialog={this.onCloseReminderDialog}
          />
        ) : null}

        {closeDialog ? (
          <Navigate
            to={parentFolderId !== null ? "/" + parentFolderId : "/"}
            replace={true}
          />
        ) : null}
      </div>
    );
  }
}
