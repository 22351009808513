import React, { Component } from "react";

export default class DeleteDialog extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.delete = this.delete.bind(this);
  }

  close() {
    this.props.onDeleteConfirm(false);
  }

  delete() {
    this.props.onDeleteConfirm(true);
  }

  render() {
    return (
      <div className="dialog_wrapper">
        <div className="dialog warning_dialog">
          <h1>Warning</h1>
          <p>Do you really want to delete this {this.props.type}?</p>

          <div className="div_dialog_buttons">
            <div className="div_dialog_button">
              <button className="dialog_confirm" onClick={this.delete}>
                Delete
              </button>
            </div>
            <div className="div_dialog_button">
              <button className="dialog_close" onClick={this.close}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
