import React, { Component } from "react";
import DrawingNoteService from "../../services/drawing-note.service";
import CookieHelper from "../../cookie-helper";
import DeleteDialog from "../dialog/delete-dialog.component";
import { Navigate } from "react-router-dom";
import methods from "../../methods";
import reportVisitService from "../../reporing-visit/report-visit.service";

export default class DrawingNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.getNote = this.getNote.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.drawNote = this.drawNote.bind(this);
    this.setHeightOfCanvas = this.setHeightOfCanvas.bind(this);
    this.drawGrid = this.drawGrid.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.close = this.close.bind(this);
    this.drawLine = this.drawLine.bind(this);

    this.state = {
      drawingNote: {},
      change: false,
      loadingNote: false,
      showDeleteDialog: false,
      syncIntervalId: "",
      parentId: null,
      closeDialog: false,
    };
  }

  componentDidMount() {
    let id = "";
    let parentId = null;

    if (window.location.pathname.startsWith("/drawing")) {
      id = window.location.pathname.split("/")[2];
    } else {
      id = window.location.pathname.split("/")[3];
      parentId = window.location.pathname.split("/")[1];
    }

    this.getNote(id);

    this.setState({
      parentId: parentId,
    });

    document.addEventListener("keydown", this.handleEscapeKey, false);
    reportVisitService.report(window.location.pathname, document.title);
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
    document.removeEventListener("keydown", this.handleEscapeKey, false);
  }

  getNote(id) {
    this.setState({
      loadingNote: true,
    });
    DrawingNoteService.get(id)
      .then((response) => {
        CookieHelper.extendValidity();

        const note = response.data;

        this.changeColor(note.color);
        this.drawNote(note);

        this.setState({
          drawingNote: note,
          change: false,
          loadingNote: false,
        });

        document.title = note.title + " | Notes";
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      const parentId = this.state.parentId;
      const id = this.state.drawingNote.id;
      DrawingNoteService.delete(id)
        .then(() => {
          this.setState({
            closeDialog: true,
          });
        })
        .catch((e) => {
          methods.processError(e);
        });
    } else {
      this.setState({
        showDeleteDialog: false,
      });
    }
  }

  close() {
    this.setState({
      closeDialog: true,
    });
  }

  delete() {
    this.setState({
      showDeleteDialog: true,
    });
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      if (this.state.showDeleteDialog) {
        this.setState({
          showDeleteDialog: false,
        });
      } else {
        this.close();
      }
    } else if (e.key === "Delete" && e.ctrlKey) {
      e.preventDefault();
      this.delete();
    }
  }

  drawNote(note) {
    const canvas = document.getElementById("drawingNote");

    const coefficient = canvas.clientWidth / 1000;
    canvas.width = canvas.clientWidth;

    this.setHeightOfCanvas(note, coefficient, canvas);

    const ctx = canvas.getContext("2d");

    this.drawGrid(
      note,
      ctx,
      coefficient,
      canvas.clientWidth,
      canvas.clientHeight
    );

    note.drawingNoteElements.forEach((element) => {
      this.drawLine(element, ctx, coefficient);
    });
  }

  drawGrid(note, ctx, coefficient, width, height) {
    const ruleHeight = 60 * coefficient;
    const gridSpace = 30 * coefficient;

    switch (note.drawingNoteGridType) {
      case "DOT":
        for (let x = gridSpace; x < width; x += gridSpace) {
          for (let y = gridSpace; y < height; y += gridSpace) {
            this.drawGridLine(ctx, coefficient, x, y, x, y);
          }
        }
        break;
      case "RULES":
        for (let y = ruleHeight; y < height; y += ruleHeight) {
          this.drawGridLine(ctx, coefficient, 0, y, width, y);
        }
        break;
      case "SQUARE":
        for (let y = gridSpace; y < height; y += gridSpace) {
          this.drawGridLine(ctx, coefficient, 0, y, width, y);
        }

        for (let x = gridSpace; x < width; x += gridSpace) {
          this.drawGridLine(ctx, coefficient, x, 0, x, height);
        }
        break;
      case "NONE":
      default:
        break;
    }
  }

  drawGridLine(ctx, coefficient, startX, startY, endX, endY) {
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = 4 * coefficient;
    ctx.strokeStyle = "#808080";
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
  }

  setHeightOfCanvas(note, coefficient, canvas) {
    note.drawingNoteElements.forEach((element) => {
      element.coordinates.forEach((coordinate) => {
        const y1 = coordinate.y * coefficient;
        if (canvas.height < y1 + 400) {
          canvas.height = y1 + 400;
        }
      });
    });
  }

  // draw a line
  drawLine = (element, ctx, coefficient) => {
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    let color = element.color;

    if (color.length > 7) {
      ctx.globalAlpha = 0.6;
      color = color.replace("#40", "#");
      ctx.strokeStyle = color;
    } else {
      ctx.globalAlpha = 1;
      ctx.strokeStyle = color;
    }

    if (element.pen) {
      // eslint-disable-next-line default-case
      switch (element.strokeWidth) {
        case "STROKE_1":
          ctx.lineWidth = 2 * coefficient;
          break;
        case "STROKE_2":
          ctx.lineWidth = 4 * coefficient;
          break;
        case "STROKE_3":
          ctx.lineWidth = 7 * coefficient;
          break;
        case "STROKE_4":
          ctx.lineWidth = 11 * coefficient;
          break;
        case "STROKE_5":
          ctx.lineWidth = 15 * coefficient;
          break;
        case "STROKE_6":
          ctx.lineWidth = 20 * coefficient;
          break;
        case "STROKE_7":
          ctx.lineWidth = 25 * coefficient;
          break;
      }
    } else {
      // eslint-disable-next-line default-case
      switch (element.strokeWidth) {
        case "STROKE_1":
          ctx.lineWidth = 7 * coefficient;
          break;
        case "STROKE_2":
          ctx.lineWidth = 14 * coefficient;
          break;
        case "STROKE_3":
          ctx.lineWidth = 23 * coefficient;
          break;
        case "STROKE_4":
          ctx.lineWidth = 37 * coefficient;
          break;
        case "STROKE_5":
          ctx.lineWidth = 51 * coefficient;
          break;
        case "STROKE_6":
          ctx.lineWidth = 64 * coefficient;
          break;
        case "STROKE_7":
          ctx.lineWidth = 80 * coefficient;
          break;
      }
    }

    const x = element.coordinates[0].x * coefficient;
    const y = element.coordinates[0].y * coefficient;
    ctx.moveTo(x, y);

    for (let i = 1; i < element.coordinates.length; i++) {
      const prevCoordinates = element.coordinates[i - 1];
      const coordinates = element.coordinates[i];

      const c1X = prevCoordinates.x * coefficient;
      const c1Y = prevCoordinates.y * coefficient;
      const c2X = coordinates.x * coefficient;
      const c2Y = coordinates.y * coefficient;
      const midX = (c1X + c2X) / 2;
      const midY = (c1Y + c2Y) / 2;

      ctx.quadraticCurveTo(c1X, c1Y, midX, midY);
    }

    ctx.stroke();
  };

  changeColor = (colorToChange) => {
    var tempBackgroundClass, tempHeaderBackgroundClass;
    tempBackgroundClass = "background_" + colorToChange;
    tempHeaderBackgroundClass = "background_" + colorToChange;

    this.setState((prevState) => {
      return {
        drawingNote: {
          ...prevState.drawingNote,
          color: colorToChange,
        },
        background_color_class: tempBackgroundClass,
        header_background_color: tempHeaderBackgroundClass,
        change: true,
      };
    });
  };

  render() {
    return (
      <div>
        {this.state.loadingNote ? <div id="loader" /> : null}

        <header className={this.state.background_color_class}>
          <button
            title="Close"
            className="action_button action_close action_button_left"
            onClick={this.close}
          />

          <nav>
            <ul>
              <li>
                <button
                  title="Delete"
                  className="action_button action_delete"
                  onClick={this.delete}
                />
              </li>
            </ul>
          </nav>
        </header>

        <div className="note_container">
          <input
            disabled
            placeholder="Title"
            className="edit_title"
            type="text"
            value={this.state.drawingNote.title}
          />

          <div className="drawing_wrapper">
            <div className="outer_container_scroll_canvas">
              <div className="inner_container_scroll">
                <canvas className="canvas" id="drawingNote" />
              </div>
            </div>
          </div>
        </div>
        {this.state.showDeleteDialog ? (
          <DeleteDialog onDeleteConfirm={this.onDeleteConfirm} type="drawing" />
        ) : null}

        {this.state.closeDialog ? (
          <Navigate
            to={this.state.parentId !== null ? "/" + this.state.parentId : "/"}
            replace={true}
          />
        ) : null}
      </div>
    );
  }
}
