import React, { Component } from "react";
import TagsService from "../../services/tags.service";
import methods from "../../methods";

import DeleteDialog from "../dialog/delete-dialog.component";

export default class EditTagsDialog extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.save = this.save.bind(this);
    this.addNewTag = this.addNewTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.onChangeNewTagTitle = this.onChangeNewTagTitle.bind(this);
    this.onChangeTagTitle = this.onChangeTagTitle.bind(this);
    this.delete = this.delete.bind(this);
    this.getTagGroup = this.getTagGroup.bind(this);
    this.onKeyDownTag = this.onKeyDownTag.bind(this);
    this.onKeyDownTitle = this.onKeyDownTitle.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);

    this.state = {
      tagGroup: {},
      tempTagTitle: "",
      loadingTag: false,
      showDeleteDialog: false,
    };
  }

  componentDidMount() {
    const id = this.props.id;
    if (id === null || id === undefined) {
      const tempTagGroup = {
        title: "",
        tags: [],
      };

      this.setState({
        tagGroup: tempTagGroup,
        change: true,
      });
    } else {
      this.getTagGroup(id);
    }
    document.addEventListener("keydown", this.handleEscapeKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeKey, false);
  }

  getTagGroup(id) {
    this.setState({
      loadingTag: true,
    });
    TagsService.get(id)
      .then((response) => {
        this.setState({
          tagGroup: response.data,
          loadingTag: false,
        });
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  addNewTag() {
    const item = {
      title: this.state.tempTagTitle,
    };

    this.state.tagGroup.tags.push(item);

    this.setState({
      tempTagTitle: "",
    });
  }

  removeTag(deleteIndex) {
    const tempTags = [];
    this.state.tagGroup.tags.forEach((tag, index) => {
      if (deleteIndex !== index) {
        tempTags.push(tag);
      }
    });

    this.setState((prevState) => {
      return {
        tagGroup: {
          ...prevState.tagGroup,
          tags: tempTags,
        },
      };
    });
  }

  onChangeTagTitle(title, changeIndex) {
    const tempTags = [];
    this.state.tagGroup.tags.forEach((tag, index) => {
      if (changeIndex !== index) {
        tempTags.push(tag);
      } else {
        tag.title = title;
        tempTags.push(tag);
      }
    });

    this.setState((prevState) => {
      return {
        tagGroup: {
          ...prevState.tagGroup,
          tags: tempTags,
        },
      };
    });
  }

  onChangeNewTagTitle(e) {
    this.setState({
      tempTagTitle: e.target.value,
    });
  }

  onChangeTitle(e) {
    const newTitle = e.target.value;
    this.setState((prevState) => {
      return {
        tagGroup: {
          ...prevState.tagGroup,
          title: newTitle,
        },
      };
    });
  }

  save() {
    this.setState({
      loadingTag: true,
    });

    const tagGroup = this.state.tagGroup;
    if (tagGroup.id === null || tagGroup.id === undefined) {
      TagsService.create(tagGroup)
        .then(() => {
          this.props.onClose();
        })
        .catch((e) => {
          methods.processError(e);
        });
    } else {
      TagsService.update(this.state.tagGroup)
        .then(() => {
          this.props.onClose();
        })
        .catch((e) => {
          methods.processError(e);
        });
    }
  }

  delete() {
    this.setState({
      showDeleteDialog: true,
    });
  }

  onDeleteConfirm(confirm) {
    if (confirm) {
      this.setState({
        loadingTag: true,
      });

      const id = this.state.tagGroup.id;
      if (id === null || id === undefined) {
        this.props.onClose();
      } else {
        TagsService.delete(id)
          .then(() => {
            this.props.onClose();
          })
          .catch((e) => {
            methods.processError(e);
          });
      }
    } else {
      this.setState({
        showDeleteDialog: false,
      });
    }
  }

  handleEscapeKey(e) {
    if (e.key === "Escape") {
      if (this.state.showDeleteDialog) {
        this.setState({
          showDeleteDialog: false,
        });
      } else {
        this.save();
      }
    } else if (e.key === "Delete" && e.ctrlKey) {
      e.preventDefault();
      this.delete();
    } else if ((e.key === "s" || e.key === "S") && e.ctrlKey) {
      e.preventDefault();
      this.save();
    }
  }

  onKeyDownTag = (e) => {
    if (e.key === "Enter") {
      this.addNewTag();
    }
  };

  onKeyDownTitle = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.type === "checklist") {
        document.getElementById("checklist_add_title").focus();
      } else {
        document.getElementById("content").focus();
      }
    }
  };

  render() {
    return (
      <div className="dialog_wrapper">
        {this.state.loadingTag ? <div id="loader2" /> : null}

        <div className="dialog item_dialog">
          <div className="dialog_header background_default">
            <div className="dialog_title">
              <button
                title="Close"
                className="action_button action_close"
                onClick={this.save}
              />
              <input
                maxLength={50}
                placeholder="Title"
                className="edit_title_folder"
                type="text"
                value={this.state.tagGroup.title}
                onKeyDown={this.onKeyDownTitle}
                onChange={this.onChangeTitle}
              />
            </div>

            <nav>
              <ul>
                <li>
                  <button
                    title="Delete"
                    className="action_button action_delete"
                    onClick={this.delete}
                  />
                </li>
              </ul>
            </nav>
          </div>

          <div className="dialog_container tag_dialog_container">
            <div className="div_add_item_to_checklist">
              <button
                title="Add new tag"
                className="button_add_tag"
                onClick={this.addNewTag}
              />

              <input
                type="text"
                autoComplete="off"
                placeholder="New tag"
                value={this.state.tempTagTitle}
                className="tag_add_title"
                onKeyDown={this.onKeyDownTag}
                onChange={this.onChangeNewTagTitle}
              />
            </div>
            <div className="outer_container_scroll edit_tag_scroll">
              <div className="inner_container_scroll">
                {this.state.tagGroup.tags &&
                  this.state.tagGroup.tags.map((item, index) => (
                    <div className="tag_row_edit">
                      <input
                        type="text"
                        className="tag_title"
                        value={item.title}
                        onChange={(e) =>
                          this.onChangeTagTitle(e.target.value, index)
                        }
                      />

                      <button
                        title="Delete tag"
                        className="button_delete_tag"
                        onClick={() => this.removeTag(index)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {this.state.showDeleteDialog ? (
          <DeleteDialog onDeleteConfirm={this.onDeleteConfirm} />
        ) : null}
      </div>
    );
  }
}
